import {AppBar, Container, Grid, Toolbar} from "@mui/material";
import Header from "./Header.tsx";
import Footer from "./Footer.tsx";
import {Outlet} from "react-router-dom";
import {FC} from "react";

const Layout:FC = () => {
  return (
    <Grid container direction="column" sx={{minHeight: '100vh'}}>
      <Grid item>
        <AppBar position="relative" color="transparent">
          <Toolbar sx={{py: 2}}>
            <Header/>
          </Toolbar>
        </AppBar>
      </Grid>
      <Grid item flexGrow={1}>
        <Container maxWidth="md">
          <Outlet />
        </Container>
      </Grid>
      <Grid item>
        <Container maxWidth="md">
          <Footer />
        </Container>
      </Grid>
    </Grid>
  )
};

export default Layout;