import databreach from './databreach';
import lifeinsurance from './lifeinsurance';
import {DialogExtension} from "../../types/dialog.ts";

const dialogs: {
  [key: string]: DialogExtension,
} = {
  databreach,
  lifeinsurance,
};

export default dialogs;