import {
  FormControl,
  FormLabel,
  InputAdornment,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import {FC, useCallback, useMemo, useState} from "react";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import useLabelRenderer from "../../hooks/useTitelRenderer.tsx";
import countries from "../../config/countries.ts";
import {CountryCodePhoneField} from "../../types/elements.ts";
import {FieldValue} from "../../types/dialog.ts";
import {phoneCountryCodeRegex} from "../../helper/helper.ts";
import FormLabelWithInfo from "../FormLabelWithInfo.tsx";
import FormInfo from "../FormInfo.tsx";

const Phone: FC<{
  field: CountryCodePhoneField,
  onChange: (value: FieldValue) => void,
  value: string,
  name: string,
  error: string | null,
  disabled: boolean,
  adornment?: {
    end?: string,
  },
  type: 'tel',
}> = (
  {
    field,
    onChange,
    value,
    name,
    error,
    disabled,
    type,
    adornment,
  }
) => {
  const renderLabel = useLabelRenderer();

  const label = renderLabel(field.label);
  const inputLabel = renderLabel(field.inputLabel);
  const placeholder = renderLabel(field.placeholder);
  const [infoOpen, setInfoOpen] = useState(false);
  const [countryCode, setCountryCode] = useState(() => {
    const [,countryCode] = (value || "+49").match(new RegExp(phoneCountryCodeRegex)) || [];
    return countryCode;
  });

  const [,, phoneNumber] = useMemo(
    () => (value || "+49").match(new RegExp(phoneCountryCodeRegex + "(.*)$")) || [],
    [value]
  );

  const handleChange = useCallback((countryCode: string, phoneNumber: string) => {
    const normalizedPhoneNumber = [
      (value: string) => (value as string).trim(),
      (value: string) => (value as string).replace(/[^0-9]/g, ''),
      (value: string) => (value as string).replace(/^0/g, ''),
      (value: string) => (value as string).substring(0, 15),
      (value: string) => (value as string).trim(),
    ].reduce((curr, next) => next(curr), phoneNumber);

    onChange(normalizedPhoneNumber ? `${countryCode}${normalizedPhoneNumber}` : "");
    setCountryCode(countryCode);
  }, [onChange]);

  return (
    <FormControl fullWidth={true}>
      {
        label && (
          <>
            {
              field.info ? (
                <>
                  <FormLabelWithInfo
                    id={`${name}-label`}
                    onInfoClick={() => setInfoOpen(!infoOpen)}
                  >
                    {renderLabel(label)}
                  </FormLabelWithInfo>
                  {!field.info?.after && infoOpen && (<FormInfo info={field.info} />)}
                </>
              ): (
                <FormLabel id={`${name}-label`}>
                  {renderLabel(label)}
                </FormLabel>
              )
            }
          </>
        )
      }
      <TextField
        id={name}
        aria-labelledby={`${name}-label`}
        label={inputLabel}
        placeholder={placeholder}
        onChange={({target: {value}}) => handleChange(countryCode, value)}
        variant="outlined"
        value={phoneNumber || ""}
        name={name}
        disabled={disabled}
        error={!!error}
        helperText={error}
        type={type}
        InputProps={{
          startAdornment:
            <InputAdornment disableTypography sx={{color: '#252534'}} position="start">
              <CountryCodeSelect value={countryCode} onChange={(value) => handleChange(value, phoneNumber)} />
              {countryCode}
            </InputAdornment>,
          ...( adornment?.end ? {endAdornment: <InputAdornment disableTypography sx={{color: '#252534'}} position="end">{adornment?.end}</InputAdornment> } : {}),
        }}
      />
      {field.info?.after && infoOpen && (<FormInfo info={field.info} />)}
    </FormControl>
  );
};


const CountryCodeSelect: FC<{
  value: string,
  onChange: (value: string) => void,
}> = ({ value, onChange }) => {
  const filteredCountries = useMemo(() => countries
    .filter(({country_code}) => country_code.indexOf('-') < 0), []);

  return (
    <Select
      disableUnderline={true}
      variant="standard"
      value={value || "+49"}
      onChange={({target: {value}}) => onChange(value)}
      renderValue={(value) => {
        const country = filteredCountries.find(({country_code}) => country_code === (value || "+49"))
        return (
          country ? <span className={`fi fi-${country.iso_code.short.toLowerCase()}`}></span> : value
        )
      }}
    >
      {filteredCountries.map(({iso_code: {short}, country_code,name}, i) => (
        <MenuItem key={`${country_code}-${i}`} value={country_code}>
          <span className={`fi fi-${short.toLowerCase()}`}></span>&nbsp;{name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default Phone;
