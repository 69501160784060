import {DialogExtension} from "../../types/dialog";
import {
  Alert,
  AlertTitle, Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import {faAt, faEnvelopeOpenText, faCheck, faXmark, faCircleDot} from '@fortawesome/free-solid-svg-icons';
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ClaimData, HeaderLink, JumpinData} from "../../types/lifeinsurance/jumpin.ts";
import apiGetUidResolve from "../../api/lifeinsurance/apiGetUidResolve.ts";
import {ContractExists, INSURANCE_YEAR_FROM, INSURANCE_YEAR_TO} from "../../mock/dialogs/lifeinsurance.tsx";
import MarkedText from "../../components/MarkedText.tsx";
import apiPostPassword from "../../api/lifeinsurance/apiPostPassword.ts";
import apiPostData from "../../api/lifeinsurance/apiPostData.ts";
import apiGetClaimData from "../../api/lifeinsurance/apiGetClaimData.ts";
import apiGetDocumentRequest from "../../api/lifeinsurance/apiGetDocumentRequest.ts";
import {CheckPayload} from "../../types/lifeinsurance/payloads.ts";
import apiPostNew from "../../api/lifeinsurance/apiPostNew.ts";
import tuevsaarland from "../../assets/tuevsaarland.webp";
import {merge} from "lodash";

const parseLinkParts = (headerLinks: string | null): HeaderLink[] =>
  (headerLinks?.split(', ') || [])
    .map<HeaderLink>((link) =>
      link?.split(';')
        ?.map(v => v.split('='))
        ?.reduce<Partial<HeaderLink>>((acc, v) => {
          if (v.length === 2) {
            acc[v[0].trim() as keyof HeaderLink] = v[1]
              .trim()
              .replace(/^"/g, '')
              .replace(/"$/g, '');
          } else {
            const handinLink = v[0].trim()
              .replace(/^</g, '')
              .replace(/>$/g, '');
            acc.link = handinLink;
            acc.id = handinLink.split('/').find((p) => p.match(/^\d+$/)) || "";
          }
          return acc;
      }, {}) as HeaderLink
    );

const lifeinsurance: DialogExtension<JumpinData> = {
  name: 'lifeinsurance',
  hotline: {
    number: '0211 33 99 66 00',
  },
  jumpin: {
    load: {
      pre: async (params, prevData) => {
        const uid = params.get('id');

        if (!uid && !prevData) {
          return null;
        }

        const utm_source = params.get('utm_source');
        const utm_medium = params.get('utm_medium');
        const utm_campaign = params.get('utm_campaign');
        const utm_term = params.get('utm_term');
        const utm_content = params.get('utm_content');
        const gclid = params.get('gclid');
        //const fbclid = params.get('fbclid');
        const ttclid = params.get('ttclid');

        let res;
        try {
          if (uid) {
            res = await apiGetUidResolve(uid);
            if (!res.ok) {
              throw new Error('Invalid');
            }
          } else {
            res = await apiPostNew({
              customer: prevData?.customer || {},
            });

            if (!res.ok) {
              throw new Error('Invalid');
            }
          }

          //ToDo: Check if all ids are the same from the links. Maybe we have a link with multiple ids
          const links = parseLinkParts(res.headers.get('Link') || null);
          const id = links[0].id;

          const claimRes = await apiGetClaimData(id);
          const { customer: customerData, contract, legal_insurance, _type } = await claimRes.json() as ClaimData;
          const { password_set, ...customer } = customerData;

          const jumpinData: JumpinData = {
            uid,
            utm: merge({
              source: utm_source,
              medium: utm_medium,
              campaign: utm_campaign,
              term: utm_term,
              content: utm_content,
              gclid,
              //fbclid,
              ttclid,
            }, prevData?.utm || {}),
            id,
            links,
            contract: {
              ...contract,
              ...(contract?.contract_start ? {
                contract_start: contract.contract_start.split('-')[0],
              } : {}),
            },
            legal_insurance,
            customer,
            _type,
            password_set: password_set || false,
          };

          return jumpinData;
        } catch {
          return null;
        }
      },
      post: async (_, jumpinData) => {
        if (!jumpinData) {
          return ['https://www.helpcheck.de/lebensversicherung-widerrufen'];
        }
        return ['check'];
      }
    },
  },
  steps: {
    'check': {
      title: 'Kostenlose Vertragsprüfung',
      text: () => (
        <Typography variant="body1">
          In den nächsten beiden Schritten stellen Sie unseren Experten alle notwendigen Daten zur Verfügung, um eine
          umfassende Prüfung Ihrer Ansprüche gegen Ihre Versicherungsgesellschaft durchführen zu können.
        </Typography>
      ),
      fields: {
        'contracts_to_check': {
          after: {
            'contractsToCheckNotice': {
              component: (data) =>
                data.contracts_to_check === '2+' && (
                  <Alert severity="info">
                    <AlertTitle><b>Mehrere Verträge</b></AlertTitle>
                    Bitte wählen Sie zunächst einen Ihrer Verträge aus und vervollständigen Sie die erforderlichen
                    Angaben. Im Anschluss können Sie einen weiteren Vertrag zur Prüfung einreichen
                  </Alert>
                )
            }
          },
        },
        'rsv': {
          initialValue: (_, context) =>
            context?.jumpin?.contract?.insurance_company || undefined,
        },
        'insurance_year': {
          required: (data) => !data?.insurance_year_dont_know,
          disabled: (data) => !!data?.insurance_year_dont_know,
          initialValue: (_, context) =>
            context?.jumpin?.contract?.contract_start || undefined,
        },
        'insurance_year_dont_know': {
          required: (data) => !data?.insurance_year,
          sx: {
            mt: {
              xs: -6
            }
          }
        },
        'contract_type': {
          initialValue: (_, context) =>
            context?.jumpin?.contract?.type || undefined,
        },
        'contract_exists': {
          initialValue: (_, context) =>
            context?.jumpin?.contract?.status || undefined,
        },
        'insurance_last_payment': {
          hidden: (data) => !data?.contract_exists,
          initialValue: (_, context) =>
            context?.jumpin?.contract?.last_payment?.amount || undefined,
        },
        'insurance_value': {
          hidden: (data) => !data?.contract_exists,
          initialValue: (_, context) =>
            context?.jumpin?.contract?.cancellation_payout?.amount ||
              context?.jumpin?.contract?.expiration_payout?.amount ||
              context?.jumpin?.contract?.current_repurchase_value?.amount ||
              undefined,
        },
        'insurance_expiry_date': {
          hidden: (data) =>
            data?.contract_exists !== ContractExists.QUITTED &&
            data?.contract_exists !== ContractExists.EXPIRED,
          initialValue: (_, context) =>
            context?.jumpin?.contract?.cancellation_payout?.date ||
            context?.jumpin?.contract?.expiration_payout?.date ||
            undefined,
        },
        'legal_protection_insurance': {
          hidden: (_, context) => context?.jumpin?._type === "enigma_without_rsv",
          required: (_, context) => context?.jumpin?._type !== "enigma_without_rsv",
          after: {
            'legalProtectionInsuranceNotice': {
              component: (data) =>
                data.legal_protection_insurance === 'no' && (
                  <Alert severity="info">
                    <AlertTitle><b>Keine Rechtsschutzversicherung</b></AlertTitle>
                    <Typography variant="body2">
                      Viele Verfahren können außergerichtlich geklärt werden. Wir streben auch in jedem Fall immer zuerst
                      eine außergerichtliche Lösung an.
                    </Typography>
                    <Typography variant="body2">
                      Manche Versicherungen erzwingen aber leider ein Gerichtsverfahren.
                    </Typography>
                    <Typography variant="body2">
                      In diesen Fällen wird eine Rechtsschutzversicherung benötigt.
                    </Typography>
                    <Typography variant="body2">
                      Unsere Vertragsprüfung ist für Sie aber in jedem Fall kostenlos!
                    </Typography>
                  </Alert>
                )
            }
          },
          initialValue: (_, context) =>
            typeof context?.jumpin?.legal_insurance?.concluded_before_2019 === "boolean" ?? undefined,
        },
        'legal_protection_insurance_year': {
          hidden: (_, context) => context?.jumpin?._type === "enigma_without_rsv",
          required: (data, context) => context?.jumpin?._type !== "enigma_without_rsv" && !data?.legal_protection_insurance_year_dont_know,
          disabled: (data) => !!data?.legal_protection_insurance_year_dont_know,
          initialValue: (_, context) =>
            context?.jumpin?.legal_insurance?.concluded_before_2019 ?? undefined,
        },
        'legal_protection_insurance_year_dont_know': {
          hidden: (_, context) => context?.jumpin?._type === "enigma_without_rsv",
          sx: {
            mt: {
              xs: -6
            }
          }
        },
        'phone': {
          required: (_, context) => !context?.jumpin?.customer?.contact?.phone_number,
          hidden: (_, context) => !!context?.jumpin?.customer?.contact?.phone_number
        },
      },
      footer: [
        {
          type: 'next',
          label: 'Weiter',
          breakpoints: {
            xs: 12,
            md: 4,
          }
        }
      ],
      bottom: {
        actions: [
          {
            type: 'link',
            label: (
              <Grid container alignItems="flex-end" justifyContent="flex-end">
                <Grid item xs={6} sm={6} md={5}>
                  <Box
                    component="img"
                    sx={{
                      height: 'auto',
                      width: '100%',
                      pt: {
                        md: 2,
                      },
                    }}
                    alt=""
                    src={tuevsaarland}
                  />
                </Grid>
              </Grid>
            ),
            target: "_blank",
            href: 'https://www.tuev-saar.de/zertifikat/tk45052/',
          }
        ]
      },
      next: async (data, context) => {
        const {
          rsv,
          insurance_year,
          insurance_year_dont_know,
          contract_type,
          contract_exists,
          insurance_last_payment,
          insurance_value,
          insurance_expiry_date,
          legal_protection_insurance,
          legal_protection_insurance_year,
          legal_protection_insurance_year_dont_know,
          phone,
          notice,
        } = data;

        if (insurance_year < INSURANCE_YEAR_FROM || insurance_year > INSURANCE_YEAR_TO) {
          return ['thank_you_out'];
        }

        const payload: Partial<CheckPayload> = {
          ...(context?.jumpin?._type !== "enigma_without_rsv" && legal_protection_insurance ? {
            legal_insurance: {
              //id: legal_protection_insurance,
              concluded_before_2019: legal_protection_insurance_year_dont_know ? null : legal_protection_insurance_year === 'before_2019'
            }
          } : {}),
          customer: {
            contact: {
              phone_number: phone || context.jumpin.customer.contact.phone_number,
              email: context.jumpin.customer.contact.email,
            },
          },
          note: notice,
          utm: {
            ...(context.jumpin?.utm || {})
          },
          contract: {
            type: contract_type,
            insurance_company: rsv,
            contract_start: insurance_year_dont_know ? null : `${insurance_year}-01-01`,
            last_payment: {
              date: null,
              amount: insurance_last_payment * 100,
              currency: "euro"
            }
          }
        };

        if (contract_exists === ContractExists.QUITTED) {
          payload.contract = {
            ...payload.contract,
            status: "cancelled",
            cancellation_payout: {
              date: insurance_expiry_date,
              amount: insurance_value * 100,
              currency: "euro"
            }
          };
        } else if (contract_exists === ContractExists.EXPIRED) {
          payload.contract = {
            ...payload.contract,
            status: "expired",
            expiration_payout: {
              date: insurance_expiry_date,
              amount: insurance_value * 100,
              currency: "euro"
            }
          };
        } else {
          payload.contract = {
            ...payload.contract,
            status: "running",
            current_repurchase_value: {
              date: null,
              amount: insurance_value * 100,
              currency: "euro"
            }
          };
        }

        await apiPostData(context.jumpin?.id, payload);
        return ['handin'];
      },
      condition: async (_, context, stepErrors) => {
        if (!context?.jumpin) {
          //return 'https://www.helpcheck.de/lebensversicherung-widerrufen';
        }

        const insurance_year = context.check?.insurance_year || context.jumpin?.contract?.contract_start;
        if (
          insurance_year >= INSURANCE_YEAR_FROM &&
          insurance_year <= INSURANCE_YEAR_TO &&
          ![
            'rsv',
            'insurance_year',
            'contract_type',
            'contract_exists',
            'phone',
          ].some((fieldName) => Object.keys(stepErrors).includes(fieldName))
        ) {
          return 'handin';
        }
      },
    },
    'handin': {
      title: 'Reichen Sie hier Ihre Unterlagen ein',
      text: (_, context) => (
        <Grid container direction="column" spacing={4} rowSpacing={8}>
          <Grid item>
            <Typography variant="body1">
              Damit unsere unabhängigen Versicherungsexperten den Vertrag zuverlässig auf Fehler prüfen können, benötigen
              wir die <b>folgenden ursprünglichen Vertragsunterlagen</b> aus {context.check?.insurance_year || context.jumpin.contract.contract_start} von Ihnen:
            </Typography>
          </Grid>
          <Grid item>
            <Alert severity="info" icon={false}>
              <AlertTitle><b>1. Antrag</b></AlertTitle>
              <p>
                Bei einem Antrag handelt es sich um ein Formular, das vom Kunden in der Regel handschriftlich ausgefüllt
                und am Ende unterzeichnet wurde.
              </p>

              <AlertTitle><b>2. Versicherungsschein / Police</b></AlertTitle>
              <p>
                Bei einem Antrag handelt es sich um ein Formular, das vom Kunden in der Regel handschriftlich ausgefüllt
                und am Ende unterzeichnet wurde.
              </p>

              <AlertTitle><b>3. Begleitschreiben</b></AlertTitle>
              <p>
                Bei einem Antrag handelt es sich um ein Formular, das vom Kunden in der Regel handschriftlich ausgefüllt
                und am Ende unterzeichnet wurde.
              </p>

              {!context.check?.insurance_value ||
                context.jumpin.contract.cancellation_payout.amount ||
                context.jumpin.contract.expiration_payout.amount ||
                context.jumpin.contract.current_repurchase_value.amount && (
                <>
                  <AlertTitle><b>4. Letzte Standmitteilung</b></AlertTitle>
                  <p>
                    Bei einem Antrag handelt es sich um ein Formular, das vom Kunden in der Regel handschriftlich ausgefüllt
                    und am Ende unterzeichnet wurde.
                  </p>
                </>
              )}
            </Alert>
          </Grid>
        </Grid>
      ),
      content: (data) => (!!data?.contract_documents_exists_dont_know || data?.contract_documents_exists !== 'no') && (
        <>
          <Typography variant="body1">
            <b>Unterlagen später nachreichen</b>
            <br/>
            Dokumente nicht zur Hand?<br/>
            Selbstverständlich können Sie uns alle oder noch fehlende Unterlagen auch per E-Mail, Post oder WhatsApp
            zuschicken:
          </Typography>
          <List>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <FontAwesomeIcon icon={faAt} size="2xl" />
              </ListItemAvatar>
              <ListItemText primary="Per E-Mail an:"
                            secondary="help@helpcheck.de"
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <FontAwesomeIcon icon={faEnvelopeOpenText} size="2xl" />
              </ListItemAvatar>
              <ListItemText
                primary="Per Post an:"
                secondary={
                  <>
                    <Typography variant="body1">
                      helpcheck GmbH<br/>
                      Königsallee 14<br/>
                      40212 Düsseldorf
                    </Typography>
                    <br/>
                    <Typography variant="body1">
                      Wir scannen Ihre Unterlagen <b>kostenlos</b> ein und senden Ihnen Originale per Einschreiben
                      zurück. Kopien sind dabei ebenso ausreichend.
                    </Typography>
                  </>
                }
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <FontAwesomeIcon icon={faWhatsapp} size="2xl" />
              </ListItemAvatar>
              <ListItemText primary="Per WhatsApp an:" secondary="0211 33 99 66 00"/>
            </ListItem>
          </List>
        </>
      ),
      fields: {
        'contract_documents_exists': {
          disabled: (data) => !!data?.contract_documents_exists_dont_know,
        },
        'contract_documents_exists_dont_know': {
          after: {
            'contractDocumentsExistsPartial': {
              component: (data) => {
                if (!data.contract_documents_exists_dont_know) {
                  if (data.contract_documents_exists === 'partially') {
                    return (
                      <Alert severity="info">
                        <AlertTitle><b>Unterlagen unvollständig</b></AlertTitle>
                        Laden Sie gern einfach die Unterlagen hoch, die Sie noch haben. Sollten dann noch notwendige
                        Unterlagen fehlen, kommen wir im Nachgang noch einmal auf Sie zu.
                      </Alert>
                    )
                  } else if (data.contract_documents_exists === 'no') {
                    return (
                      <Alert severity="info">
                        <AlertTitle><b>Fehlende Unterlagen</b></AlertTitle>
                        <p>
                          Für die Prüfung Ihres Vertrages benötigen wir die oben genannten Unterlagen.
                        </p>
                        <p>
                          Bei Verträgen, die noch bestehen, oder vor weniger als 10 Jahren ausbezahlt wurden, können Sie
                          Ihre Unterlagen bei Ihrer Versicherung jedoch nachträglich neu ausstellen lassen.
                        </p>
                        <p>
                          Wir unterstützen Sie dabei indem wir ein Schreiben an Ihre Versicherungsgesellschaft senden und
                          die fehlenden Unterlagen anfordern.
                        </p>
                      </Alert>
                    )
                  }
                }
                return null;
              }
            }
          },
          sx: {
            mt: {
              xs: -6
            }
          },
        },
        'contract_documents_upload': {
          hidden: (data) => !!data?.contract_documents_exists_dont_know || data?.contract_documents_exists === 'no',
          disabled: (data) => !!data?.contract_documents_upload_hand_in_later,
          //required: (data) => !data?.contract_documents_exists_dont_know && !data?.contract_documents_upload_hand_in_later,
        },
        'contract_documents_upload_hand_in_later': {
          hidden: (data) => !!data?.contract_documents_exists_dont_know || data?.contract_documents_exists === 'no',
          disabled: (data) => data?.contract_documents_upload?.length > 0,
          //required: (data) => !data?.contract_documents_exists_dont_know && data?.contract_documents_upload?.length === 0,
          sx: {
            mt: {
              xs: -6
            }
          },
        },
      },
      footer: [
        {
          type: 'next',
          label: (data) => data.contract_documents_exists === 'no' ? 'Unterlagen neu anfordern' : 'Weiter',
          breakpoints: {
            xs: 12,
            md: 4,
          }
        }
      ],
      next: async (data, context) => {
        if (!data?.contract_documents_exists_dont_know && data?.contract_documents_exists === 'no') {
          const res = await apiGetDocumentRequest(context.jumpin?.id);
          const links = parseLinkParts(res.headers.get('Link') || null);
          const requestLink = links.find((l) => l.rel === 'document-request');
          if (!requestLink) {
            throw Error('No link found');
          }
          return [requestLink.link];
        }

        return ['thank_you'];
      },
      condition: async (_, context) => {
        if (!context?.jumpin) {
          return 'https://www.helpcheck.de/lebensversicherung-widerrufen';
        }
      },
    },
    'thank_you': {
      title:  () => (
        <Typography variant="h1">
          <MarkedText>
            Vielen Dank!
          </MarkedText>
        </Typography>
      ),
      text: () => (
        <Typography variant="body1">
          Wir haben Ihre Angaben erhalten und melden uns nach Prüfung in Kürze bei Ihnen.
        </Typography>
      ),
      fields: {
        'password': {
          before: {
            "createAccount": {
              component: () => (
                <Grid container direction="column" spacing={4} rowSpacing={8}>
                  <Grid item>
                    <Typography variant="body1">
                      Legen Sie sich jetzt Ihr <b>persönliches helpcheck Kundenkonto</b> an, um jederzeit den Status Ihrer
                      Vertragsprüfung zu sehen, Unterlagen nachzureichen und weitere Verträge zu prüfen.
                    </Typography>
                  </Grid>
                </Grid>
              )
            }
          },
          after: {
            "passord_requirements": {
              component: (data) => {
                const password = data?.password as string || "";
                const requirements = [
                  {
                    label: 'Mindestens 8 Zeichen',
                    valid: password?.length >= 8,
                  },
                  {
                    label: 'Mindestens 1 Großbuchstabe',
                    valid: /[A-Z]/.test(password),
                  },
                  {
                    label: 'Mindestens 1 Kleinbuchstabe',
                    valid: /[a-z]/.test(password),
                  },
                  {
                    label: 'Mindestens 1 Zahl',
                    valid: /[0-9]/.test(password),
                  },
                  {
                    label: 'Mindestens 1 Sonderzeichen',
                    valid: /[^A-Za-z0-9]/.test(password),
                  }
                ];

                const minRequirementsValid = requirements
                  .slice(1)
                  .filter(({ valid }) => valid)
                  .length >= 3
                ;

                return (
                  <List disablePadding dense>
                    {
                      requirements.map(({ label, valid }, i) => (
                        <ListItem disablePadding key={i}>
                          <ListItemIcon>
                            {
                              valid ? (
                                <FontAwesomeIcon color="green" icon={faCheck} />
                              ) : (
                                <FontAwesomeIcon
                                  color={(!minRequirementsValid || i === 0) && password?.length > 0 ? "red" : "gray"}
                                  icon={(!minRequirementsValid || i === 0) && password?.length > 0 ? faXmark : faCircleDot}
                                />
                              )
                            }
                          </ListItemIcon>
                          <ListItemText primary={label} />
                        </ListItem>
                      ))
                    }
                  </List>
                )
              }
            }
          },
        },
        'password_repeat': {
          validators: [
            {
              type: 'function',
              message: 'Die Passwörter stimmen nicht überein.',
              validate: (_, value, data) => data.password === value,
            }
          ],
        }
      },
      footer: [
        {
          type: 'next',
          label: 'Kundenkonto erstellen',
          breakpoints: {
            xs: 12,
            md: 4,
          },
        }
      ],
      next: async (data, context) => {
        try {
          await apiPostPassword(context.jumpin?.id, data);
        } catch {
          return [undefined, undefined, {
            'password': 'Das Passwort ist zu unsicher. Bitte wählen Sie ein anderes.',
          }];
        }

        return ['thank_you_2'];
      },
      condition: async (_, context) => {
        if (!context?.jumpin) {
          return 'https://www.helpcheck.de/lebensversicherung-widerrufen';
        } else if (!context || Object.keys(context || {}).length <= 0) {
          return 'https://www.helpcheck.de/lebensversicherung-widerrufen';
        } else if (context?.jumpin?.password_set || context.thank_you) {
          return 'thank_you_2';
        }
      },
    },
    'thank_you_2': {
      title:  () => (
        <Typography variant="h1">
          <MarkedText>
            Vielen Dank!
          </MarkedText>
        </Typography>
      ),
      text: () => (
        <>
          <Typography variant="body1">
            Wir haben Ihre Angaben erhalten und werden uns nach Abschluss.
          </Typography>
          <Typography variant="body1">
            Falls Sie einen weiteren Vertrag einreichen möchten, können Sie über den unten stehenden Button eine neue
            Prüfung starten.
          </Typography>
        </>
      ),
      condition: async (_, context) => {
        if (!context?.jumpin) {
          return 'https://www.helpcheck.de/lebensversicherung-widerrufen';
        } else if (!context || Object.keys(context || {}).length <= 0) {
          return 'https://www.helpcheck.de/lebensversicherung-widerrufen';
        }
      },
      footer: [
        {
          type: 'next',
          label: 'Weiteren Vertrag prüfen',
          breakpoints: {
            xs: 12,
            md: 4,
          },
        }
      ],
      next: ['/jumpin/:dialog'],
    },
    'thank_you_out': {
      title:  () => (
        <Typography variant="h1">
          <MarkedText>
            Vielen Dank!
          </MarkedText>
        </Typography>
      ),
      text: (_, context) => (
        <>
          <Typography variant="body1">
            Leider können wir Ihren Vertrag nicht prüfen, da
            dieser {context.check.insurance_year < INSURANCE_YEAR_FROM ? `vor ${INSURANCE_YEAR_FROM}` : `nach ${INSURANCE_YEAR_TO}`} abgeschlossen wurde.
          </Typography>
          <Typography variant="body1">
            Falls Sie einen weiteren Vertrag prüfen möchten, können Sie über den unten stehenden Button eine neue
            Prüfung starten.
          </Typography>
        </>
      ),
      condition: async (_, context) => {
        if (!context?.jumpin) {
          return 'https://www.helpcheck.de/lebensversicherung-widerrufen';
        } else if (!context || Object.keys(context || {}).length <= 0) {
          return 'https://www.helpcheck.de/lebensversicherung-widerrufen';
        }
      },
      footer: [
        {
          type: 'prev',
          label: 'Daten anpassen',
          breakpoints: {
            xs: 12,
            md: 4,
          },
        },
        {
          type: 'next',
          label: 'Weiteren Vertrag prüfen',
          breakpoints: {
            xs: 12,
            md: 4,
          },
        }
      ],
      prev: 'check',
      next: ['/jumpin/:dialog'],
    },
  }
};

export default lifeinsurance;