import {FieldValue} from "../../types/dialog.ts";

const apiGetAffected = async (phone?: FieldValue, email?: FieldValue) => {
    const params = [
      phone ? `q[]=${encodeURIComponent(phone as string)}` : null,
        email ? `q[]=${encodeURIComponent(email as string)}` : null,
    ].filter(Boolean);

    return await fetch(`${import.meta.env.VITE_AFFECTED_API_URL || '/api/affected'}?${params.join('&')}`, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
    });
}

export default apiGetAffected;