import {StepData} from "../../types/dialog.ts";
import {HandInPayload} from "../../types/payloads.ts";

const apiPostHandIn = async (id: string, data: StepData) => {
  const {
    insurance_policy_number,
    signature,
  } = data;

  const payload: HandInPayload = {
    "legal_insurance": {
      "insurance_number": insurance_policy_number as string || null
    },
    "mandate": {
      "signature": signature as string || null
    },
  };

  return await fetch(`${import.meta.env.VITE_API_URL || '/api/v1'}/privacy-law/data-breach/${id}`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(payload),
  });
};

export default apiPostHandIn;
