const apiPostUpload = async (path: string, formData: FormData, onProgress: (loaded: number, total: number) => void) => {
  const xhr = new XMLHttpRequest();
  return new Promise((resolve) => {
    xhr.upload.addEventListener("progress", (event) => {
      if (event.lengthComputable) {
        onProgress(event.loaded, event.total);
      }
    });
    xhr.addEventListener("loadend", () => {
      resolve(xhr.readyState === 4 && xhr.status >= 200 && xhr.status <= 299);
    });
    xhr.open("POST", `${import.meta.env.VITE_API_URL || '/api/v1'}${path}`, true);
    xhr.send(formData);
  });
};

export default apiPostUpload;
