import {ThemeProvider} from "@mui/material";
import theme from "./config/theme.ts";
import Router from "./components/Router.tsx";
import './App.scss'
import BreakpointProvider from "./provider/BreakpointProvider.tsx";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BreakpointProvider>
        <Router/>
      </BreakpointProvider>
    </ThemeProvider>
  )
}

export default App
