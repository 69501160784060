import {DataBreaches, DialogRes} from "../../types/dialog.ts";
import apiGetInsurances from "../../api/databreach/apiGetInsurances.ts";
import {SelectFieldOption} from "../../types/elements.ts";
import {Chip, FormLabel, Link, Typography} from "@mui/material";
import config from "../../config/config.ts";
import {phoneCountryCodeRegex} from "../../helper/helper.ts";

const databreach: DialogRes = {
  steps: {
    'check': {
      fields: {
        'phone': {
          type: 'phone',
          label: 'Ihre Telefonnummer',
          placeholder: '1601234567',
          required: (data) => !data.email,
        },
        'email': {
          type: 'email',
          label: 'Ihre E-Mail-Adresse',
          placeholder: 'E-Mail-Adresse',
          required: (data) => !data.phone,
        }
      },
    },
    'affected': {
      fields: {
        'hasRsv': {
          type: 'radio',
          label: 'Haben Sie eine Rechtsschutzversicherung?',
          options: [
            {label: <>Ja, ich bin abgesichert <Chip size="small" label="provisionsfrei" color="success" /></>, value: true},
            {label: <>Nein, ich habe keine Rechtsschutzversicherung <Chip size="small" label="25 % Erfolgsprovision" color="primary" /></>, value: false}
          ],
          required: true
        }
      },
    },
    'notAffectedPhone': {
      fields: {
        'email': {
          type: 'email',
          label: 'E-Mail-Adresse',
          required: true
        },
        'subscribe': {
          type: 'checkbox',
          label: <>
            <Typography>
              Ich möchte auch in Zukunft informiert werden, wenn meine Daten von einem Datenleck betroffen sind (kostenlos).
            </Typography>
            <Typography>
              Die <Link href="https://app.helpcheck.de/agb/datenleck" target="_blank">allgemeinen Geschäftsbedingungen</Link> sowie
              die <Link href="https://www.helpcheck.de/datenschutz" target="_blank">Datenschutzbestimmungen</Link> habe ich gelesen und akzeptiere diese.
            </Typography>
          </>,
          required: false
        }
      },
    },
    'notAffectedMail': {
      fields: {
        'phone': {
          type: 'phone',
          label: 'Telefonnummer',
          placeholder: '1601234567',
          required: false
        },
        'subscribe': {
          type: 'checkbox',
          label: <>
            <Typography>
              Ich möchte auch in Zukunft informiert werden, wenn meine Daten von einem Datenleck betroffen
              sind (kostenlos).
            </Typography>
            <Typography>
              Die <Link href="https://app.helpcheck.de/agb/datenleck" target="_blank">allgemeinen Geschäftsbedingungen</Link> sowie
              die <Link href="https://www.helpcheck.de/datenschutz" target="_blank">Datenschutzbestimmungen</Link> habe ich gelesen und
              akzeptiere diese.
            </Typography>
          </>,
          required: (data) => !data.phone,
        },
      },
    },
    'notAffectedAtAll': {
      fields: {
        'subscribe': {
          type: 'checkbox',
          label: <>
            <Typography variant="body1">
              Ich möchte auch in Zukunft informiert werden, wenn meine Daten von einem Datenleck betroffen
              sind (kostenlos).
            </Typography>
            <Typography variant="body1">
              Die <Link href="https://app.helpcheck.de/agb/datenleck" target="_blank">allgemeinen Geschäftsbedingungen</Link> sowie
              die <Link href="https://www.helpcheck.de/datenschutz" target="_blank">Datenschutzbestimmungen</Link> habe ich gelesen und
              akzeptiere diese.
            </Typography>
          </>,
          required: true,
        }
      },
    },
    'notAffectedSubscribed': {},
    'insurance': {
      fields: {
        'rsv': {
          type: 'autocomplete',
          placeholder: 'z.B. Advocard, ARAG, HUK',
          label: (_, context) => (
            <>
              Ihre Rechtsschutzversicherung
              {' '}
              {
                config?.databreach?.[context.affected.databreach as DataBreaches]?.date &&
                <Typography variant="body1" component="span">
                    (zum Zeitpunkt des Datenlecks, also im {config.databreach[context.affected.databreach as DataBreaches].date})
                </Typography>
              }
            </>
          ),
          options: async (): Promise<SelectFieldOption[]> => {
            try {
              const res = await apiGetInsurances();
              const insurances = await res.json();
              return insurances.map((insurance: {name: string, unique_id: string}) => ({
                label: `${insurance.name}`,
                value: insurance.unique_id
              }))
            } catch {
              return []
            }
          },
          info: {
            text: (
              <Typography variant="body1">
                Entscheidend ist, dass Sie zum Zeitpunkt, in dem das Datenleck aufgetreten ist, eine
                Rechtsschutzversicherung hatten. Falls Sie zu dem Zeitpunkt keine Rechtsschutzversicherung hatten,
                wählen Sie auf der Seite davor bitte "Nein".
              </Typography>
            )
          },
          required: true
        },
        'email': {
          type: 'email',
          placeholder: (_, context) => context.affected.email ? context.affected.email as string : 'mail@beispiel.de' as string,
          inputLabel: (_, context) =>
            `E-Mail-Adresse${context.affected.email ? ' (falls abweichend von der geprüften)' : ''}`,
          required: (_, context) => !context.affected.email
        },
        'phone': {
          type: 'phone',
          inputLabel: (_, context) =>
            `Telefonnummer${context.affected.phone ? ' (falls abweichend von der geprüften)' : ''}`,
          placeholder: (_, context) =>
            (context.affected.phone as string || "").match(new RegExp(phoneCountryCodeRegex + '(.*)$'))?.[2] || '1601234567' as string
          ,
          required: false
        },
        'gender': {
          type: 'radio',
          options: [
            {label: 'Herr', value: 'male'},
            {label: 'Frau', value: 'female'}
          ],
          row: {
            xs: true
          },
          required: true,
          breakpoints: {
            md: 6,
          },
          after: {
            'space': {
              component: <div>&nbsp;</div>,
              breakpoints: {
                md: 6
              },
              hidden: {
                xs: true,
                md: false,
              }
            }
          }
        },
        'firstName': {
          type: 'input',
          inputLabel: 'Vorname',
          required: true,
          breakpoints: {
            md: 6,
          },
        },
        'lastName': {
          type: 'input',
          inputLabel: 'Nachname',
          required: true,
          breakpoints: {
            md: 6,
          },
        },
        'street': {
          type: 'street_housenumber',
          inputLabel: 'Straße und Hausnummer',
          required: true
        },
        'zipcode': {
          type: 'zipcode',
          inputLabel: 'PLZ',
          required: true,
          breakpoints: {
            md: 6,
          },
        },
        'city': {
          type: 'city',
          inputLabel: 'Stadt',
          required: true,
          breakpoints: {
            md: 6,
          },
        },
        'username': {
          type: 'username',
          placeholder: 'meinname',
          required: false,
          hidden: (_, context) => !['facebook', 'twitter'].includes(context.affected.databreach)
        },
        'data_privacy': {
          type: 'checkbox',
          label: () => <Typography>
            Ich habe die <Link href="https://app.helpcheck.de/agb/datenleck" target="_blank">allgemeinen Geschäftsbedingungen</Link> sowie
            die <Link href="https://www.helpcheck.de/datenschutz" target="_blank">Datenschutzbestimmungen</Link> gelesen und akzeptiere diese.
            </Typography>,
          required: true
        }
      },
    },
    'no_insurance': {
      fields: {
        'email': {
          type: 'email',
          placeholder: (_, context) => context.affected.email ? context.affected.email as string : 'mail@beispiel.de' as string,
          inputLabel: (_, context) =>
            `E-Mail-Adresse${context.affected.email ? ' (falls abweichend von der geprüften)' : ''}`,
          required: (_, context) => !context.affected.email
        },
        'phone': {
          type: 'phone',
          inputLabel: (_, context) =>
            `Telefonnummer${context.affected.phone ? ' (falls abweichend von der geprüften)' : ''}`,
          placeholder: (_, context) =>
            (context.affected.phone as string || "").match(/^\+\d{1,3} (.*)$/)?.[1] || '1601234567' as string
          ,
          required: false
        },
        'gender': {
          type: 'radio',
          options: [
            {label: 'Mann', value: 'male'},
            {label: 'Frau', value: 'female'}
          ],
          row: {
            xs: true
          },
          required: true,
          breakpoints: {
            md: 6,
          },
          after: {
            'space': {
              component: <div>&nbsp;</div>,
              breakpoints: {
                md: 6
              },
              hidden: {
                xs: true,
                md: false,
              }
            }
          }
        },
        'firstName': {
          type: 'input',
          inputLabel: 'Vorname',
          required: true,
          breakpoints: {
            md: 6,
          },
        },
        'lastName': {
          type: 'input',
          inputLabel: 'Nachname',
          required: true,
          breakpoints: {
            md: 6,
          },
        },
        'street': {
          type: 'street_housenumber',
          inputLabel: 'Straße und Hausnummer',
          required: true
        },
        'zipcode': {
          type: 'zipcode',
          inputLabel: 'PLZ',
          required: true,
          breakpoints: {
            md: 6,
          },
        },
        'city': {
          type: 'city',
          inputLabel: 'Stadt',
          required: true,
          breakpoints: {
            md: 6,
          },
        },
        'username': {
          type: 'username',
          required: false,
          placeholder: 'meinname',
          hidden: (_, context) => !['facebook', 'twitter'].includes(context.affected.databreach)
        },
        'data_privacy': {
          type: 'checkbox',
          label: () => <>
            <Typography component="span">
              Ich habe die <Link href="https://app.helpcheck.de/agb/datenleck" target="_blank">allgemeinen Geschäftsbedingungen</Link> sowie
              die <Link href="https://www.helpcheck.de/datenschutz" target="_blank">Datenschutzbestimmungen</Link> gelesen und akzeptiere diese.
            </Typography> <Typography component="span" sx={{fontWeight: 'bold'}}>
            Im Erfolgsfall erhält helpcheck 25% der ausgezahlten Entschädigung.
            </Typography>
          </>,
          required: true
        }
      },
    },
    'insurance_power_of_attorney': {
      fields: {
        'insurance_policy_number': {
          type: 'input',
          label: <>
            <FormLabel>
              Versicherungsnummer Ihrer Rechtschutzversicherung
            </FormLabel>
            <Typography variant="body1">
              Die Versicherungsnummer finden Sie auf Ihrer Versicherungskarte oder im Online-Portal.
            </Typography>
          </>,
          placeholder: 'Versicherungsnummer',
        },
        'insurance_policy_number_hand_in_later': {
          type: 'checkbox',
          label: "Reiche ich nach",
        },
        'signature': {
          type: 'signature',
          label: <>
            <FormLabel>Ihre Unterschrift</FormLabel>
            <Typography variant="body1" sx={{display: {xs: 'block', md: 'none'}}}>
              Zeichnen Sie Ihre Unterschrift mit dem Finger in das weiße Feld.
            </Typography>
            <Typography variant="body1" sx={{display: {xs: 'none', md: 'block'}}}>
              Zeichnen Sie Ihre Unterschrift in das weiße Feld. Halten Sie dafür die linke Maustaste gedrückt.
            </Typography>
          </>,
          required: true
        }
      },
    },
    'no_insurance_power_of_attorney': {
      fields: {
        'signature': {
          type: 'signature',
          label: <>
            <FormLabel>Ihre Unterschrift</FormLabel>
            <Typography variant="body1" sx={{display: {xs: 'block', md: 'none'}}}>
              Zeichnen Sie Ihre Unterschrift mit dem Finger in das weiße Feld.
            </Typography>
            <Typography variant="body1" sx={{display: {xs: 'none', md: 'block'}}}>
              Zeichnen Sie Ihre Unterschrift in das weiße Feld. Halten Sie dafür die linke Maustaste gedrückt.
            </Typography>
          </>,
          required: true
        }
      },
    },
    'thank_you': {
      fields: {
        'password': {
          type: 'password',
          label: 'Passwort festlegen',
          placeholder: 'Sicheres Passwort festlegen',
          required: true
        },
        'password_repeat': {
          type: 'password',
          label: 'Passwort wiederholen',
          placeholder: 'Passwort wiederholen',
          required: true,
        }
      },
    },
    'thank_you_2': {},
    'registered': {},
  }
};

export default databreach;