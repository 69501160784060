import {createContext} from "react";
import {Step, StepData} from "../types/dialog.ts";
import {Fields} from "../types/elements.ts";

type StepContextType = {
  data: StepData,
  step: Step<Fields>,
};

const StepContext = createContext<StepContextType>({} as StepContextType);

export default StepContext;
