import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import useLabelRenderer from "../../hooks/useTitelRenderer.tsx";
import {FC, useContext, useEffect, useMemo, useState} from "react";
import {RadioField, RadioFieldOption} from "../../types/elements.ts";
import { FieldValue } from "../../types/dialog.ts";
import BreakpointContext from "../../context/BreakpointContext.tsx";
import apiGetFromUrl from "../../api/apiGetFromUrl.ts";
import DialogContext from "../../context/DialogContext.tsx";
import StepContext from "../../context/StepContext.tsx";
import FormLabelWithInfo from "../FormLabelWithInfo.tsx";
import FormInfo from "../FormInfo.tsx";

const RadioInput: FC<{
  field: RadioField,
  onChange: (value: FieldValue) => void,
  value: string,
  name: string,
  error: string | null,
  disabled: boolean,
}> = (
  {
    field,
    onChange,
    value,
    name,
    error,
    disabled,
  }
) => {
  const renderLabel = useLabelRenderer();
  const { solve } = useContext(BreakpointContext);
  const { data: context } = useContext(DialogContext);
  const { data } = useContext(StepContext);

  const row = useMemo(() => solve<boolean>(field.row, false), [field.row, solve]);

  const [options, setOptions] = useState<readonly RadioFieldOption[]>([]);
  const [loading, setLoading] = useState(true);
  const [infoOpen, setInfoOpen] = useState(false);

  const selectedValue = useMemo(() =>
      options.find(({value: optionValue}) => optionValue === value) ?? undefined
    , [options, value]);

  useEffect(() => {
    let active = true;

    (async () => {
      let options: RadioFieldOption[];
      if (typeof field.options === 'function') {
        options = await field.options(data, context);
      } else if (typeof field.options === 'string') {
        const res = await apiGetFromUrl(field.options);
        options = await res.json();
      } else {
        options = field.options;
      }

      if (!active) {
        return;
      }

      setOptions(options);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [context, data, field]);

  return (
    <FormControl fullWidth={true}>
      {
        field.info ? (
          <>
            <FormLabelWithInfo
              id={`${name}-label`}
              color="primary"
              focused={false}
              onInfoClick={() => setInfoOpen(!infoOpen)}
            >
              {renderLabel(field.label)}
            </FormLabelWithInfo>
            {!field.info?.after && infoOpen && (<FormInfo info={field.info} />)}
          </>
        ): (
          <FormLabel id={`${name}-label`} color="primary" focused={false}>
            {renderLabel(field.label)}
          </FormLabel>
        )
      }
      {
        !loading &&
          <RadioGroup
              sx={{marginTop: 2}}
              row={row}
              aria-labelledby={`${name}-label`}
              defaultValue={""}
              value={selectedValue !== undefined ? selectedValue.value : ""}
              onChange={(_, value) => onChange(value)}
              name={name}
          >
            {options.map((option) => (
              <FormControlLabel
                key={`${name}-${option.label}`}
                value={option.value}
                control={<Radio/>}
                label={option.label}
                disabled={disabled}
              />
            ))}
          </RadioGroup>
      }
      {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
      {field.info?.after && infoOpen && (<FormInfo info={field.info} />)}
    </FormControl>
  );
};

export default RadioInput;
