import {DialogData, StepData} from "../../types/dialog.ts";
import {RegisterPayload} from "../../types/payloads.ts";
import {StreetHousenumberRegex} from "../../config/fields/validators.ts";

const apiPostRegistration = async (data: StepData, context: DialogData) => {
  const {
    rsv,
    gender,
    firstName,
    lastName,
    street: street_housenumber,
    zipcode,
    city,
    username,
  } = data;

  const [, street, , street_number] = (street_housenumber as string).match(StreetHousenumberRegex) || [];

  const payload: RegisterPayload = {
    tokens: {
      // ToDo: Sende immer beides auch wenn er von der anderen Seite kommt
      ...(context.affected.phone && {phone_number: (context.affected.phone as string)}),
      ...(context.affected.email && {email: (context.affected.email as string)}),
      preferred: [Object.keys(context.affected.data)[0]],
    },
    customer: {
      gender,
      first_name: firstName as string,
      last_name: lastName as string,
      address: {
        street: street as string,
        ...(street_number ? {street_number: street_number as string} : {}),
        zip_code: zipcode as string,
        city: city as string,
      },
      contact: {
        ...(data.phone || context.affected.phone ? { phone_number: data.phone as string || context.affected.phone as string } : {}),
        email: data.email as string || context.affected.email as string
      }
    },
    legal_insurance: {
      id: rsv as string || null
    },
    ...(username ? {
      additional_information: {
        facebook_profile_name: username as string,
      },
    } : {}),
    utm: {
      ...(context.jumpin?.utm || {})
    }
  };

  return fetch(`${import.meta.env.VITE_API_URL || '/api/v1'}/registration/privacy-law/data-breach`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(payload),
  });
};

export default apiPostRegistration;
