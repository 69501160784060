import {CheckPayload} from "../../types/lifeinsurance/payloads.ts";

const apiPostData = async (id: string, payload: Partial<CheckPayload>) => {
  return fetch(`${import.meta.env.VITE_API_URL || '/api/v1'}/life-insurance/${id}`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(payload),
  });
};

export default apiPostData;
