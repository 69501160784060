import {createContext} from "react";
import {Breakpoint} from "@mui/system/createTheme/createBreakpoints";

export type BreakpointSolver = <T = unknown>(configs: Partial<{ [key in Breakpoint]: T }> | undefined, fallback: T) => T;

type BreakpointContext = {
  breakpoint: Breakpoint,
  solve: BreakpointSolver,
};

const BreakpointContext = createContext<{
  breakpoint: Breakpoint,
  solve: BreakpointSolver,
}>({breakpoint: 'xs'} as BreakpointContext);

export default BreakpointContext;