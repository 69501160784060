const apiGetClaimData = async (id: string) => {
  return fetch(`${import.meta.env.VITE_API_URL || '/api/v1'}/privacy-law/data-breach/${id}`, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });
};

export default apiGetClaimData;
