import {AutocompleteField, SelectFieldOption} from "../../types/elements.ts";
import useLabelRenderer from "../../hooks/useTitelRenderer.tsx";
import {useEffect, useMemo, useState} from "react";
import apiGetFromUrl from "../../api/apiGetFromUrl.ts";
import {
  Autocomplete,
  CircularProgress,
  createFilterOptions,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField
} from "@mui/material";
import {FieldValue} from "../../types/dialog.ts";
import FormLabelWithInfo from "../FormLabelWithInfo.tsx";
import FormInfo from "../FormInfo.tsx";

const AsyncAutocomplete = (
  {
    field,
    value,
    onChange,
    error,
    name,
    disabled
  }: {
    field: AutocompleteField;
    value: FieldValue,
    onChange: (value: FieldValue) => void,
    error: string | null,
    name: string,
    disabled: boolean,
  }
) => {
  const renderLabel = useLabelRenderer();

  const [open, setOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [options, setOptions] = useState<readonly SelectFieldOption[]>([]);
  const loading = options.length === 0;

  const selectedValue = useMemo(() =>
      options.find(({value: optionValue}) => optionValue === value) ?? null
    , [options, value]);

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: SelectFieldOption) => option.label,
  });

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      let options: SelectFieldOption[];
      if (typeof field.options === 'function') {
        options = await field.options();
      } else if (typeof field.options === 'string') {
        const res = await apiGetFromUrl(field.options);
        options = await res.json();
      } else {
        options = field.options;
      }

      if (!active) {
        return;
      }

      setOptions(options);
    })();

    return () => {
      active = false;
    };
  }, [field, loading]);

  return (
    <FormControl fullWidth={true}>
      {
        field.info ? (
          <>
            <FormLabelWithInfo
              id={`${name}-label`}
              color="primary"
              focused={false}
              onInfoClick={() => setInfoOpen(!infoOpen)}
            >
              {renderLabel(field.label)}
            </FormLabelWithInfo>
            {!field.info?.after && infoOpen && (<FormInfo info={field.info} />)}
          </>
        ): (
          <FormLabel id={`${name}-label`} color="primary" focused={false}>
            {renderLabel(field.label)}
          </FormLabel>
        )
      }
      <Autocomplete
        sx={{marginTop: 2}}
        disablePortal
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        filterOptions={filterOptions}
        value={selectedValue}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        getOptionLabel={(option) => option.label}
        options={options}
        loading={loading}
        disabled={disabled}
        onChange={(_, value) => onChange(value?.value ?? null)}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!error}
            placeholder={renderLabel(field.placeholder)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
      {field.info?.after && infoOpen && (<FormInfo info={field.info} />)}
    </FormControl>
  );
};

export default AsyncAutocomplete;