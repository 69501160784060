import {DialogData, StepData} from "../types/dialog.ts";
import countries from "../config/countries.ts";
import {Breakpoint} from "@mui/system/createTheme/createBreakpoints";
import {BreakpointSolver} from "../context/BreakpointContext.tsx";
import {generatePath} from "react-router-dom";

export type EvalCondition = boolean | Partial<{ [key in Breakpoint]: boolean }> | ((data: StepData, context: DialogData) => boolean);
export const evalCondition = (condition: EvalCondition | undefined, data: StepData, context: DialogData, solve: BreakpointSolver) => {
  if (typeof condition === 'function') {
    return condition(data, context);
  }
  if (typeof condition === 'object') {
    return solve(condition, false);
  }
  return condition === undefined ? false : condition;
}

const filteredCountryCodes = countries
  .filter(({country_code}) => country_code.indexOf('-') < 0)
  .map(({country_code}) => parseInt(country_code.replace('+', '')))
  .sort((a, b) => a < b ? 1 : -1)
  .reduce((acc, cur) => {
    if (!acc.includes(cur)) acc.push(cur);
    return acc;
  }, [] as number[]);

export const phoneCountryCodeRegex = `^((?:\\+|00)(?:${filteredCountryCodes.join('|')}))`;

export const generateUrl = (url: string, dialog: string) => {
  if (url?.match(/^(http|https)?:\/\//)) {
    return url;
  }

  let next = url;
  if (url.indexOf(':dialog') < 0) {
    next = `/:dialog/${url}`;
  }

  return generatePath(next, {dialog});
};
