import {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
  Fragment, useRef,
} from "react";
import {
  useLoaderData,
  useSubmit,
  useFormAction,
  useParams,
  useNavigate,
  useLocation
} from "react-router-dom";
import {Backdrop, Breakpoints, Button, CircularProgress, Grid, Link} from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/de';

import Field from "../components/Field";
import DialogContext from "../context/DialogContext.tsx";
import {DialogData, DialogName, FieldValue, Step, StepData, StepErrors, StepName} from "../types/dialog.ts";
import StepContext from "../context/StepContext.tsx";
import useLabelRenderer from "../hooks/useTitelRenderer.tsx";
import {evalCondition, generateUrl} from "../helper/helper.ts";
import validators, { validatorErrors } from "../config/fields/validators.ts";
import { flatten } from 'flat';
import {Fields} from "../types/elements.ts";
import config from "../config/config.ts";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import BreakpointContext, {BreakpointSolver} from "../context/BreakpointContext.tsx";
import {ValidatorError} from "../types/validator.ts";

export type DialogParams = {
  dialog: string;
  step?: string;
}

const DialogPage: FC = () => {
  const stepData = useLoaderData() as StepData;
  const submit = useSubmit();
  const action = useFormAction();
  const navigate = useNavigate();

  const {step: stepName, dialog: dialogName} = useParams<DialogParams>();

  const {dialog, data: context, setData: setContext} = useContext(DialogContext);
  const { solve } = useContext(BreakpointContext);

  const [errors, setErrors] = useState<StepErrors>({});
  const [step, setStep] = useState<Step<Fields>>();
  const [data, setData] = useState<StepData>();
  const [loading, setLoading] = useState(false);

  const loadingStart = useRef<number>(0);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const buildDefaults = useCallback((step: Step<Fields>) => {
    const defaults: StepData = {};
    Object.entries(step.fields ?? {}).forEach(([fieldName, field]) => {
      if (field.initialValue) {
        defaults[fieldName] = typeof field.initialValue === 'function' && !(field.initialValue instanceof File) ?
          field.initialValue({}, context) :
          field.initialValue;
        return;
      }
    });
    return defaults;
  }, [context]);

  const handleValidate = useCallback(async (fields: Step<Fields>["fields"], data: StepData, context: DialogData, solve: BreakpointSolver) => {
    const errors: StepErrors = {};
    const fieldValidatorDefaultErrors = flatten<{[key: string]: ValidatorError},ValidatorError>(validatorErrors);
    await Promise.all(Object.entries(fields ?? {})
      .filter(([, field]) => !evalCondition(field.hidden, data, context, solve))
      .filter(([, field]) => !evalCondition(field.disabled, data, context, solve))
      .map(async ([fieldName, field]) => {
        // If required but has no value set
        if (evalCondition(field.required, data, context, solve) &&
          (
            (field.type !== 'radio' && !data[fieldName]) ||
            (field.type === 'radio' && (
                typeof field.options === 'function' &&
                !(await field.options(data, context)).find((option) => option.value === data[fieldName]) ||
                typeof field.options !== 'function' &&
                !field.options.find((option) => option.value === data[fieldName])
              )
            )
          )
        ) {
          errors[fieldName] = field.error?.empty ||
            (field.type === 'radio' || field.type === 'checkbox' ?
              "Bitte treffen Sie eine Auswahl." :
              "Bitte füllen Sie dieses Feld aus.")
          ;
          return;
        }

        // If value not set skip validators
        if (!data[fieldName]) {
          return;
        }

        const fieldErrors = {
          ...fieldValidatorDefaultErrors,
          ...(field.error ? flatten({[field.type]: field.error}) : {}) as ValidatorError,
        };
        const fieldValidators = field?.validators ?? validators[field.type] ?? [];
        // Check all validators
        for (const validator of fieldValidators ?? []) {
          switch (validator.type) {
            case 'function':
              if (!validator.validate(fieldName, data[fieldName], data, context)) {
                const errorKey = `${field.type}.${validator.message}`;
                errors[fieldName] = fieldErrors[errorKey] || 'Bitte überprüfen Sie Ihre Eingabe.';
                return;
              }
              break;
            case 'regex':
              if (typeof data[fieldName] !== "string" || !(new RegExp(validator.regex)).test(data[fieldName] as string)) {
                const errorKey = `${field.type}.${validator.message}`;
                errors[fieldName] = fieldErrors[errorKey] || 'Bitte überprüfen Sie Ihre Eingabe.';
                return;
              }
          }
        }
      })
    );

    return errors;
  }, []);

  useEffect(() => {
    const step = dialog.steps[stepName as string];
    const defaults = buildDefaults(step);
    const data = {...defaults, ...stepData};

    (async () => {
      const errors= await handleValidate(step.fields, data, context, solve);
      const redirect = step.condition && await step.condition(data, context, errors);
      if (redirect) {
        if (redirect.match(/^(http|https)?:\/\//)) {
          window.location.href = redirect;
          return;
        }
        navigate(generateUrl(redirect, dialogName as DialogName));
        return;
      }
    })();

    setStep(step);
    setData(data);

    setTimeout(() => {
      setLoading(false);
    }, Math.max(config.loading.delay - (Date.now() - loadingStart.current), 0));

    return () => {
      setErrors({});
    }
  }, [buildDefaults, context, dialog, dialogName, handleValidate, navigate, solve, stepData, stepName]);

  const handleNext = useCallback(async (data: StepData, nextUrl?: string, stepAction?: string) => {
    setLoading(true);

    loadingStart.current = Date.now();

    setContext({
      [stepName as StepName]: data
    });

    await submit(JSON.stringify({
      next: nextUrl,
      data,
    }), {
      action: stepAction ? generateUrl(stepAction, dialog.name) : action,
      method: "post",
      encType: "application/json",
    });
  }, [action, dialog.name, setContext, stepName, submit]);

  const handlePrev = useCallback((redirect?: string) => {
    if (!redirect) return null;
    return navigate(generateUrl(redirect, dialog.name));
  }, [dialog.name, navigate]);

  const handleChange = useCallback((fieldName: string, value: FieldValue) => {
    setData((prevData) => prevData?.[fieldName] === value ? prevData : ({
      ...(prevData || {}),
      [fieldName]: value
    }));

    setErrors((errors) => {
      if (!errors[fieldName]) return errors;
      const newErrors = {...errors};
      delete newErrors[fieldName];
      return newErrors
    });
  }, []);

  if (step === undefined || data === undefined) {
    return null;
  }

  return (
    <StepContext.Provider value={{data, step}}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
        <Backdrop
          sx={{ backgroundColor: 'rgba(255, 255, 255, 0.9)', color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <StepContent
          onChange={handleChange}
          onNext={handleNext}
          onPrev={handlePrev}
          onError={setErrors}
          onValidate={handleValidate}
          loading={loading}
          errors={errors}
        />
      </LocalizationProvider>
    </StepContext.Provider>
  )
};

const StepContent = (
  {
    onChange,
    onNext,
    onPrev,
    onError,
    onValidate,
    errors,
    loading,
  }: {
    onChange: (fieldName: string, value: FieldValue) => void,
    onNext: (data: StepData, nextUrl?: string, action?: string) => void,
    onPrev: (route?: string) => void,
    onError: (errors: StepErrors) => void,
    onValidate: (fields: Step<Fields>["fields"], data: StepData, context: DialogData, solve: BreakpointSolver) => Promise<StepErrors>,
    errors: StepErrors,
    loading: boolean,
  }
) => {
  const renderLabel = useLabelRenderer();
  const {data: context} = useContext(DialogContext);
  const {data, step} = useContext(StepContext);
  const { solve } = useContext(BreakpointContext);

  const handlePrev = useCallback(async () => {
    let prev = step.prev;
    if (typeof prev === 'function') {
      prev = await prev(context) || undefined;
    }
    onPrev(prev);
  }, [context, onPrev, step]);

  const handleNext = useCallback(async () => {
    const errors = await onValidate(step.fields, data, context, solve);

    if (Object.keys(errors).length > 0) {
      onError(errors);
      return;
    }

    let next = step.next;
    if (typeof next === 'function') {
      next = await next(data, context) || undefined;
    }

    const [nextUrl, nextData, nextErrors] = next || [];

    if (nextErrors) {
      onError(nextErrors);
      return;
    }

    const stepData = {
      ...data,
      ...(nextData ? {data: nextData} : {}),
    };

    onNext(stepData, nextUrl, step.action);
  }, [context, data, onError, onNext, onValidate, solve, step.action, step.fields, step.next]);

  return (
    <Grid container sx={{px: 4, py: 8}} direction="column" spacing={4} rowSpacing={8}>
      <Grid item>
        {renderLabel(step.title, "h1")}
      </Grid>
      <Grid item>
        {renderLabel(step.text, "body1")}
      </Grid>
      <Grid item container direction="row" spacing={7}>
        {
          Object.entries(step.fields ?? {})
            .filter(([, field]) => !evalCondition(field.hidden, data, context, solve))
            .map(([fieldName, field]) => (
              <Fragment key={fieldName}>
                {
                  Object.entries(field?.before ?? {})
                    .filter(([, elem]) => !evalCondition(elem.hidden, data, context, solve))
                    .map<[string, ReactNode, Partial<Breakpoints['values']>, SxProps<Theme>]>(([key, elem]) =>
                      [
                        key,
                        typeof elem.component === 'function' ? elem.component(data, context) : elem.component,
                        elem.breakpoints ?? {},
                        elem.sx ?? {}
                      ]
                    )
                    .filter(([ ,elem]) => Boolean(elem))
                    .map(([key, elem, breakpoints, sx]) =>
                      <Grid item key={`${fieldName}-${key}-before`} {...breakpoints} sx={sx}>
                        {elem}
                      </Grid>
                    )
                }
                <Grid item { ...(field.breakpoints || {}) } sx={field.sx || {}}>
                  <Field
                    key={fieldName}
                    name={fieldName}
                    field={field}
                    error={errors[fieldName] ?? null}
                    value={data[fieldName]}
                    onChange={(value) => onChange(fieldName, value)}
                  />
                </Grid>
                {
                  Object.entries(field?.after ?? {})
                    .filter(([, elem]) => !evalCondition(elem.hidden, data, context, solve))
                    .map<[string, ReactNode, Partial<Breakpoints['values']>, SxProps<Theme>]>(([key, elem]) =>
                      [
                        key,
                        typeof elem.component === 'function' ? elem.component(data, context) : elem.component,
                        elem.breakpoints ?? {},
                        elem.sx ?? {}
                      ]
                    )
                    .filter(([ ,elem]) => Boolean(elem))
                    .map(([key, elem, breakpoints, sx]) =>
                      <Grid item key={`${fieldName}-${key}-after`} {...breakpoints} sx={sx}>
                        {elem}
                      </Grid>
                    )
                }
              </Fragment>
            ))
        }
      </Grid>
      {
        step.content &&
          <Grid item>
            {renderLabel(step.content, "body1")}
          </Grid>
      }
      <Grid container spacing={4} mt={4} justifyContent={(step.footer || []).length > 1 ? "space-between" : "flex-end"}>
        {
          step.footer?.map((elem, i) => (
            <Grid item { ...(elem.breakpoints || {}) } key={i}>
              <Button
                name={`${elem.type}-${i}`}
                fullWidth={true}
                disabled={loading}
                variant="contained"
                color={elem.type === 'next' ? "primary" : "tertiary"}
                type={elem.type === 'next' ? 'submit' : 'button'}
                onClick={() => {
                  if (elem.type === 'button') {
                    elem.onClick();
                  } else if (elem.type === 'prev') {
                    handlePrev();
                  } else if (elem.type === 'next') {
                    handleNext();
                  }
                }}
              >
                {renderLabel(elem.label)}
              </Button>
            </Grid>
          ))
        }
      </Grid>
      {
        step.bottom?.title &&
          <Grid item>
            {renderLabel(step.bottom.title, "h1")}
          </Grid>
      }
      {
        step.bottom?.text &&
          <Grid item>
            {renderLabel(step.bottom.text, "body1")}
          </Grid>
      }
      {
        step.bottom?.actions && (
          <Grid container spacing={4} mt={4} justifyContent={(step.bottom.actions || []).length > 1 ? "space-between" : "flex-end"}>
            {
              step.bottom?.actions?.map((elem, i) => (
                <Grid item { ...(elem.breakpoints || {}) } key={i}>
                  {
                    elem.type === "button" ?
                      <Button
                        name={`${elem.type}-${i}`}
                        fullWidth={true}
                        disabled={loading}
                        variant="contained"
                        color={"tertiary"}
                        type={'button'}
                        onClick={() => elem.onClick()}
                      >
                        {renderLabel(elem.label)}
                      </Button>
                      : <Link href={elem.href} target={elem.target || "_blank"}>
                        {renderLabel(elem.label)}
                      </Link>
                  }

                </Grid>
              ))
            }
          </Grid>
        )
      }
    </Grid>
  )
};

export default DialogPage;

