import {Alert, AlertTitle, Grid} from "@mui/material";
import {FieldLabel} from "../types/elements.ts";
import useLabelRenderer from "../hooks/useTitelRenderer.tsx";

type FormInfoProps = {
  info: {
    title?: FieldLabel;
    text: FieldLabel;
  };
};
const FormInfo = ({info: {title, text}}: FormInfoProps) => {
  const renderLabel = useLabelRenderer();
  return (
    <Grid container direction="column">
      <Grid item xs={12} pt={4} pb={2}>
        <Alert severity="info">
          { title && <AlertTitle><b>{renderLabel(title)}</b></AlertTitle> }
          {renderLabel(text)}
        </Alert>
      </Grid>
    </Grid>
  );
};

export default FormInfo;