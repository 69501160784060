import {createContext} from "react";
import {Dialog, DialogData} from "../types/dialog.ts";

export type DialogContextType = {
  data: DialogData,
  setData: (data: DialogData) => void,
  dialog: Dialog
};

const DialogContext = createContext<DialogContextType>({
  data: {}
} as DialogContextType);

export default DialogContext;
