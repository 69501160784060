import {NewsletterPayload} from "../types/payloads.ts";
import {StepData} from "../types/dialog.ts";

const apiPostNewsletter = async (data: StepData) => {
  const { email } = data;

  const payload: NewsletterPayload = {
    gender: null,
    last_name:  null,
    email: email as string,
    field_of_law: "Datenleck",
    tag: "data-breach"
  };

  return await fetch(`${import.meta.env.VITE_API_URL || '/api/v1'}/registration/newsletter`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(payload),
  });
};

export default apiPostNewsletter;
