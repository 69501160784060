import {useCallback, useEffect, useState} from "react";
import ReactSignatureCanvas from "react-signature-canvas";
import {Box, Button, FormHelperText, FormLabel, Grid, styled, TextField} from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import useLabelRenderer from "../../hooks/useTitelRenderer.tsx";
import {SignatureField} from "../../types/elements.ts";
import {FieldValue} from "../../types/dialog.ts";

const SignatureComponent = (
  {
    value,
    field,
    onChange,
    name,
    error,
    disabled,
    className,
  }: {
    value: string,
    field: SignatureField,
    onChange: (value: FieldValue) => void,
    name: string,
    error: string | null,
    disabled: boolean,
    className?: string,
  }
) => {
  const renderLabel = useLabelRenderer();
  const [signaturePad, setSignaturePad] = useState<ReactSignatureCanvas | null>(null);

  useEffect(() => {
    if (!signaturePad || !signaturePad.isEmpty()) return;
    signaturePad?.fromDataURL(value, { ratio: 1, width: 500, height: 200});
  }, [signaturePad, value]);

  const clearSignature = useCallback(() => {
    if (!signaturePad) return;
    signaturePad.clear();
    onChange(null);
  }, [onChange, signaturePad]);

  useEffect(() => {
    if (!signaturePad) return;
    const canvas = signaturePad.getCanvas();
    let timeout: NodeJS.Timeout | null = null;
    const handleResize = () => {
      canvas.width = 0;
      canvas.height = 0;
      clearSignature();
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        canvas.width = canvas.parentElement?.clientWidth ?? 0;
        canvas.height = canvas.parentElement?.clientHeight ?? 0;
        timeout = null;
      }, 100);
    };

    if (signaturePad.isEmpty()) {
      handleResize();
    }
    window.addEventListener('resize', handleResize);
    return () => {
      if (timeout) clearTimeout(timeout);
      timeout = null;
      window.removeEventListener('resize', handleResize);
    }
  }, [clearSignature, signaturePad]);

  const handleEnd = () => {
    if (!signaturePad || signaturePad.isEmpty()) return;
    //const trimmedSignature = signaturePad.getTrimmedCanvas();
    onChange(signaturePad.toDataURL());
  };

  return (
    <Box className={className}>
      <FormLabel>{renderLabel(field.label)}</FormLabel>
      <Grid className={"Signature"}>
        <Box className={"SignatureLabel"}>
          {renderLabel(field.signatureHint)}
        </Box>
        <Box className={"SignatureControls"}>
          <Button color="primary" onClick={clearSignature}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Box>
        <Grid className={"SignatureArea"}>
          <SignatureCanvas
            onEnd={handleEnd}
            clearOnResize={false}
            ref={(ref) => setSignaturePad(ref)}
            canvasProps={{width: '100%', height: '100%', className: 'sigCanvas'}}
          />
        </Grid>
        <Grid className={"SignatureLine"}/>
      </Grid>
      {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
      
      <TextField
        id={name}
        value={value ?? ""}
        name={name}
        disabled={disabled}
        type="hidden"
        variant="standard"
      />
    </Box>
  );
};

const Signature = styled(SignatureComponent)(({ theme, error }) => ({
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  '& .Signature': {
    backgroundColor: '#fff',
    border: '1px solid',
    borderColor: error ? theme.palette.error.main : theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
  },
  '& .SignatureLine': {
    position: 'absolute',
    bottom: '10%',
    left: '5%',
    backgroundColor: theme.palette.text.primary,
    height: '1px',
    width: '90%',
    zIndex: 1,
  },
  '& .SignatureLabel': {
    position: 'absolute',
    top: theme.spacing(4),
    left: theme.spacing(4),
    zIndex: 1,
    color: theme.palette.text.primary,
    paddingLeft: theme.spacing(4),
  },

  '& .SignatureControls': {
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(4),
    zIndex: 3,
    '& button': {
      color: theme.palette.text.primary,
    }
  },
  '& .SignatureArea': {
    minHeight: '200px',
    maxHeight: '200px',
    width: '100%',
    overflowX: 'hidden',
    position: 'relative',
    zIndex: 2,
    backgroundColor: 'transparent',

    '& .sigCanvas': {
      display: 'block',
    }
  }
}));

export default Signature;