import {FormControl, FormLabel, InputAdornment} from "@mui/material";
import useLabelRenderer from "../../hooks/useTitelRenderer.tsx";
import {FC, useEffect, useState} from "react";
import {DateField, FieldLabel} from "../../types/elements.ts";
import {FieldValue} from "../../types/dialog.ts";
import FormLabelWithInfo from "../FormLabelWithInfo.tsx";
import FormInfo from "../FormInfo.tsx";
import {InputBaseComponentProps} from "@mui/material/InputBase/InputBase";
import {DatePicker} from "@mui/x-date-pickers";
import * as dayjs from "dayjs";

const Date: FC<{
  field: DateField,
  onChange: (value: FieldValue) => void,
  value: string,
  name: string,
  error: string | null,
  disabled: boolean,
  adornment?: {
    start?: FieldLabel,
    end?: FieldLabel,
  },
  inputProps?: InputBaseComponentProps,
}> = (
  {
    field,
    onChange,
    value,
    name,
    error,
    disabled,
    adornment,
    inputProps,
  }
) => {
  const renderLabel = useLabelRenderer();

  const [infoOpen, setInfoOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(value ? dayjs(value) : "");

  useEffect(() => {
    setDate(value ? dayjs(value) : "");
  }, [value]);

  const label = renderLabel(field.label);
  const inputLabel = renderLabel(field.inputLabel);
  const placeholder = renderLabel(field.placeholder);

  const inputAdornment = adornment?.start || adornment?.end;

  return (
    <FormControl fullWidth={true}>
      {
        label && (
          <>
            {
              field.info ? (
                <>
                  <FormLabelWithInfo
                    id={`${name}-label`}
                    onInfoClick={() => setInfoOpen(!infoOpen)}
                  >
                    {renderLabel(label)}
                  </FormLabelWithInfo>
                  {!field.info?.after && infoOpen && (<FormInfo info={field.info} />)}
                </>
              ): (
                <FormLabel id={`${name}-label`}>
                  {renderLabel(label)}
                </FormLabel>
              )
            }
          </>
        )
      }
      <DatePicker
        sx={{marginTop: label ? 2 : 0}}
        aria-labelledby={`${name}-label`}
        label={inputLabel}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={
          (value) => onChange(value && typeof value === 'object' ? value.format('YYYY-MM-DD') : null)
        }
        value={date}
        disabled={disabled}
        slotProps={{
          textField: {
            error: !!error,
            variant: "outlined",
            placeholder: placeholder,
            id: name,
            name: name,
            helperText: error,
            InputLabelProps: {
              ...(inputLabel && placeholder ? {shrink: true} : {}),
            },
            InputProps: {
              inputProps: {
                ...(inputProps ?? {}),
                onClick: () => setOpen(true)
              },
            },
          },
          field: {},
          inputAdornment: inputAdornment ? {
            position: adornment?.start ? "start" : "end",
            children: typeof inputAdornment === 'string' ? (
              <InputAdornment disableTypography sx={{color: '#252534'}} position="start">{inputAdornment}</InputAdornment>
            ) : renderLabel(inputAdornment),
          } : undefined,
        }}
      />
      {field.info?.after && infoOpen && (<FormInfo info={field.info} />)}
    </FormControl>
  );
};

export default Date;
