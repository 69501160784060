import {FieldValue} from "../../types/dialog.ts";
import {phoneCountryCodeRegex} from "../../helper/helper.ts";

const parsers: {
  [key: string]: (((value: FieldValue) => FieldValue))[],
} = {
  phone: [
    (value) => ((value as string) ?? "").replace(new RegExp(phoneCountryCodeRegex), "").trim(),
  ],
};

export default parsers;