const apiPostNew = async (data: any) => {
  return fetch(`${import.meta.env.VITE_API_URL || '/api/v1'}/life-insurance/new`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });
};

export default apiPostNew;
