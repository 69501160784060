import {createTheme} from "@mui/material";
import { deDE } from '@mui/material/locale';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
  }

  interface PaletteOptions {
    tertiary: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#fe8d0c',
      light: '#fe8d0c',
      dark: '#fe8d0c',
      contrastText: '#fff',
    },
    secondary: {
      main: '#044477',
      light: '#044477',
      dark: '#044477',
      contrastText: '#fff',
    },
    tertiary: {
      main: '#c4c4c4',
      contrastText: '#fff',
    },
    success: {
      main: '#75c5a3',
    },
    text: {
      primary: '#25252f', // Form Labels default state
      secondary: '#25252f',
      //disabled: '#25252f',
      //secondary: '#252534',
      //secondary: '#d2d2d4',
    },
    action: {
      active: '#044777',
      /*
      hover: string;
      hoverOpacity: number;
      selected: string;
      selectedOpacity: number;
      disabled: string;
      disabledOpacity: number;
      disabledBackground: string;
      focus: string;
      focusOpacity: number;
      activatedOpacity: number;
       */
    }
  },
  typography: {
    fontFamily: '"Work Sans Variable", "sans-serif"',
    //fontSize: '22rem',
    //fontWeightLight: 400,
    //htmlFontSize: 35,
    h1: {
      fontSize: '2.1rem',
      lineHeight: '1.1em',
      fontWeight: 600
    },
    h2: {
      //fontSize: '11rem'
    },
    h3: {
      //fontSize: '11rem'
    },
    button: {
      fontSize: '1.125rem',
      lineHeight: '1.5em',
      fontWeight: 600,
    }
  },
  spacing: 4,
  shape: {
    borderRadius: 5,
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '50px',
          textTransform: 'none',
          paddingLeft: '20px',
          paddingRight: '20px',
          boxShadow: 'none',
        }
      }
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#9BB4C9', // Farbe des Rahmens im inaktiven Zustand
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#044777', // Farbe des Rahmens im aktiven Zustand
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#044777', // Farbe des Rahmens beim Hover-Effekt
          },
        },
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: 600,
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#8e8e8e',
          //display: 'none', // Das Label nicht anzeigen
          fontWeight: 'normal',
          '&.Mui-focused': {
            color: '#044777', // Farbe des Labels im aktiven Zustand
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          flex: 1,
          width: '100%',
          '&.Mui-selected': {
            color: '#044777',
            backgroundColor: 'rgba(4, 71, 119, 0.17)',
            borderRight: '1px solid #ccc', // Linie für aktiven Status
          },
          '&:not(:last-of-type)': {
            borderRight: '1px solid #ccc', // Linie für inaktiven Status
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          paddingBottom: '2px',
          '&.Mui-checked': {
            color: '#044777',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
          '&[role="radiogroup"]': {

            border: '1px solid #9BB4C9',

            '.MuiFormControlLabel-root': {
              padding: '16.5px 14px',
              margin: 0,
              borderBottom: '1px solid #9BB4C9',
              '&:last-child': {
                borderBottom: 0,
              }
            },
            '&.MuiFormGroup-row': {
              display: 'flex',
              '.MuiFormControlLabel-root': {
                flex: 1,
                borderBottom: '0',
                borderRight: '1px solid #9BB4C9',
                '&:last-child': {
                  borderRight: 0,
                }
              },
            },
          }
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingLeft: 0,
          paddingBottom: 0,
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          borderRadius: '5px',
          color: '#fff',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginTop: 0,
          '& .MuiRadio-root': {
            //color: '#9BB4C9', // Farbe des Rahmens im inaktiven Zustand
          },
          '& .Mui-checked': {
            //color: '#044777', // Farbe des Rahmens im ausgewählten Zustand
          },
          '&:hover .MuiRadio-root': {
            //color: '#044777', // Farbe des Rahmens beim Hover-Effekt
          },
        },
      },
    },
  },
}, deDE);

export default theme;