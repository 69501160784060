import {styled} from "@mui/material";

const MarkedText = styled('span')(({theme}) => ({
  position: 'relative',
  color: '#ffffff',
  zIndex: 1,

  '&::before': {
    zIndex: '-1',
    content: '" "',
    display: 'block',
    height: '90%',
    width: '100%',
    marginLeft: '-3px',
    marginRight: '-3px',
    position: 'absolute',
    background: theme.palette.success.main,
    transform: 'rotate(.5deg)',
    top: '-1px',
    left: '-1px',
    borderRadius: '15% 15% 20% 24%',
    padding: '3px 3px 3px 3px',
  }
}));

export default MarkedText;