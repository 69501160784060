import {Box, styled} from "@mui/material";

const BorderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'stretch',

  '&::before': {
    content: '" "',
    borderRadius: '3px',
    marginRight: theme.spacing(2),
    background: '#75c5a3',
    width: '10px',
  }
}));

export default BorderBox;
