import {FC, useCallback, useContext, useMemo, useState} from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import {Fields} from "../types/elements";
import useLabelRenderer from "../hooks/useTitelRenderer.tsx";
import AsyncAutocomplete from "./fields/AsyncAutocomplete.tsx";
import Signature from "./fields/Signature.tsx";
import StepContext from "../context/StepContext.tsx";
import DialogContext from "../context/DialogContext.tsx";
import {evalCondition} from "../helper/helper.ts";
import Input from "./fields/Input.tsx";
import Phone from "./fields/Phone.tsx";
import {FieldValue} from "../types/dialog.ts";
import RadioInput from "./fields/RadioInput.tsx";
import BreakpointContext from "../context/BreakpointContext.tsx";
import Upload from "./fields/Upload.tsx";
import Date from "./fields/Date.tsx";

const Field: FC<{
  name: string,
  field: Fields,
  value: FieldValue,
  onChange: (value: FieldValue) => void,
  error: string | null,
}> = (
  {
    name,
    field,
    value: initValue,
    onChange,
    error,
  }
) => {
  const renderLabel = useLabelRenderer();
  const { data: context } = useContext(DialogContext);
  const { data } = useContext(StepContext);
  const { solve } = useContext(BreakpointContext);

  const [value, setValue] = useState<FieldValue>(() =>
    (field.parser ?? []).reduce((curr, next) => next(curr), initValue));

  const handleChange = useCallback((value: FieldValue) => {
    const normalizedValue = (field.normalizer ?? []).reduce((curr, next) => next(curr, field?.props), value);

    setValue((field.parser ?? []).reduce((curr, next) => next(curr), normalizedValue));
    onChange(normalizedValue);
  }, [field.normalizer, field.parser, field?.props, onChange]);

  const disabled = useMemo(() =>
    evalCondition(field.disabled, data, context, solve)
  , [field.disabled, data, context, solve]);

  switch(field.type) {
    case 'radio': {
      return (
        <RadioInput
          field={field}
          onChange={handleChange}
          value={value as string ?? ""}
          name={name}
          error={error}
          disabled={disabled}
        />
      );
    }
    case 'checkbox':
      return (
        <FormControl fullWidth={true} error={!!error} disabled={disabled}>
          <FormControlLabel
            control={<Checkbox sx={{alignSelf: 'flex-start', pt: 0.5}}/>}
            label={renderLabel(field.label)}
            checked={value as boolean ?? false}
            onChange={(_, value) => handleChange(value)}
            name={name}
            disabled={disabled}
          />
          { error && <FormHelperText>{error}</FormHelperText> }
        </FormControl>
      );
    case 'phone': {
      return (
        <Input
          field={field}
          onChange={handleChange}
          value={value as string ?? ""}
          name={name}
          error={error}
          disabled={disabled}
          type={'tel'}
          inputProps={field.props}
          adornment={
            {
              start: '+49 ',
              ...(field.adornment ?? {})
            }
          }
        />
      );
    }
    case 'country_code_phone': {
      return (
        <Phone
          field={field}
          onChange={handleChange}
          value={value as string ?? ""}
          name={name}
          error={error}
          disabled={disabled}
          type={'tel'}
        />
      );
    }
    case 'username':
    case 'city':
    case 'street_housenumber':
    case 'zipcode':
    case 'textarea':
    case 'input': {
      return (
        <Input
          field={field}
          onChange={handleChange}
          value={value as string ?? ""}
          name={name}
          error={error}
          disabled={disabled}
          type={'text'}
          inputProps={field.props}
          adornment={field.adornment ?? {}}
        />
      )
    }
    case 'email': {
      return (
        <Input
          field={field}
          onChange={handleChange}
          value={value as string ?? ""}
          name={name}
          error={error}
          disabled={disabled}
          type={'email'}
          inputProps={field.props}
          adornment={field.adornment ?? {}}
        />
      )
    }
    case 'number': {
      return (
        <Input
          field={field}
          onChange={handleChange}
          value={value as string ?? ""}
          name={name}
          error={error}
          disabled={disabled}
          type={'number'}
          inputProps={field.props}
          adornment={field.adornment ?? {}}
        />
      )
    }
    case 'password': {
      return (
        <Input
          field={field}
          onChange={handleChange}
          value={value as string ?? ""}
          name={name}
          error={error}
          disabled={disabled}
          type={'password'}
          inputProps={field.props}
          adornment={field.adornment ?? {}}
        />
      );
    }
    case 'autocomplete': {
      return (
        <AsyncAutocomplete
          field={field}
          onChange={handleChange}
          value={value ?? ""}
          name={name}
          error={error}
          disabled={disabled}
        />
      )
    }
    /*
    case 'select': {
      return (
        <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
          <FormLabel id={`${name}-label`}>{renderLabel(field.label)}</FormLabel>
          {
            typeof field.options === 'function' || typeof field.options === 'string' ? (
              <AsyncSelect
                field={field}
                value={value ?? ""}
                error={error}
                onChange={handleChange}
              />
            ) : (
              <Autocomplete
                disablePortal
                id={name}
                options={field.options}
                renderInput={(params) => <TextField {...params} label="Movie" />}
              />
            )
          }
          {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
        </FormControl>
      )
    }
     */
    case 'upload': {
      return (
        <Upload
          field={field}
          value={value as string ?? ""}
          onChange={handleChange}
          name={name}
          error={error}
          disabled={disabled}
          props={field.props(data, context)}
        />
      );
    }
    case 'signature': {
      return (
        <Signature
          field={field}
          value={value as string ?? ""}
          onChange={handleChange}
          name={name}
          error={error}
          disabled={disabled}
        />
      );
    }
    case 'date': {
      return (
        <Date
          field={field}
          value={value as string ?? ""}
          onChange={handleChange}
          name={name}
          error={error}
          disabled={disabled}
        />
      );
    }
  }

  return (
    <div>-------------</div>
  )
}

export default Field;