import {FC} from "react";
import {Container, Divider, Grid, Link, styled} from "@mui/material";

const Footer: FC = () => {
  return (
    <Container maxWidth="md">
      <Divider />
      <Grid
        container
        justifyContent={{xs: "flex-start", md: "flex-end"}}
        alignItems={{xs: "flex-start", md: "center"}}
        direction="row"
        sx={{py: 4}}
        spacing={8}
      >
        <Grid item>
          <FooterLink 
            href="https://www.helpcheck.de/datenschutz"
            color="primary"
            variant="body1"
            target="_blank"
          >
            Datenschutz
          </FooterLink>
        </Grid>
        <Grid item>
          <FooterLink 
            href="https://www.helpcheck.de/impressum"
            color="primary"
            variant="body1"
            target="_blank"
          >
            Impressum
          </FooterLink>
        </Grid>
      </Grid>
    </Container>
  )
};

const FooterLink = styled(Link)`
  font-size: 0.8em;
  color: #000;
`;

export default Footer;