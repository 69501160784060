import {FC} from "react";
import {Container, Grid, Link} from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import Logo from "../assets/logo.svg";
import dialogs from "../config/dialogs";
import {useParams} from "react-router-dom";
import {DialogName} from "../types/dialog.ts";
import config from "../config/config.ts";

const Header: FC = () => {
  const params = useParams();
  const hotline = dialogs[params.dialog as DialogName]?.hotline || config.hotline;

  return (
    <Container maxWidth="md">
      <Grid container justifyContent="space-between" alignItems="center" sx={{px: 4}}>
        <Grid item>
          <img src={Logo} className="logo" alt="helpcheck" />
        </Grid>
        <Grid item>
          {
            hotline && (
              <Link
                color="secondary"
                variant="body1"
                href={`tel:+49${hotline.number.replace(/[^0-9]/g, '').replace(/^0/, '')}`}
                sx={{fontWeight: 'bold'}}
              >
                <FontAwesomeIcon icon={faPhone} /> {hotline.number}
              </Link>
            )
          }
        </Grid>
      </Grid>
    </Container>
  )
}

export default Header;