import {FC, useCallback, useEffect, useMemo, useState} from "react";
import {useLoaderData, Outlet, useParams} from "react-router-dom";
import DialogContext from "../context/DialogContext.tsx";
import {Dialog, DialogData, DialogRes} from "../types/dialog.ts";
import localDialogs from "../config/dialogs";
import {DialogParams} from "../pages/Dialog.tsx";
import {merge} from "lodash";
import validators from "../config/fields/validators.ts";
import parsers from "../config/fields/parsers.ts";
import normalizers from "../config/fields/normalizers.ts";

const DialogProvider:FC = () => {
  const {data: loadedData, dialog: loadedDialog} = useLoaderData() as {
    data: DialogData,
    dialog: DialogRes
  };
  const {dialog: dialogName} = useParams<DialogParams>();

  const [context, setContext] = useState<DialogData>(loadedData);

  useEffect(() => {
    setContext(loadedData);
  }, [loadedData]);

  // Merge local dialogs definition with loaded dialogs definition
  const dialog = useMemo<Dialog>(
    () => {
      const dialog: Dialog = merge(loadedDialog, localDialogs[dialogName ?? ''] || {});
      for (const stepName in dialog.steps) {
        const step = dialog.steps[stepName];
        for (const fieldName in step.fields) {
          const field = step.fields?.[fieldName];
          if (!field?.normalizer && normalizers[field.type]) {
            field.normalizer = normalizers[field.type];
          }
          if (!field.validators && validators[field.type]) {
            field.validators = validators[field.type];
          }
          if (!field.parser && parsers[field.type]) {
            field.parser = parsers[field.type];
          }
          field.breakpoints = {
            xs: 12,
            ...(field?.breakpoints ?? {}),
          };

          for (const beforeName in field.before) {
            const before = field.before?.[beforeName];
            before.breakpoints = {
              xs: 12,
              ...(before?.breakpoints ?? {}),
            };
          }

          for (const afterName in field.after) {
            const after = field.after?.[afterName];
            after.breakpoints = {
              xs: 12,
              ...(after?.breakpoints ?? {}),
            };
          }

        }
      }
      return dialog;
    },
    [dialogName, loadedDialog]
  );

  const setData = useCallback((data: DialogData) => {
    setContext((prevContext) => merge(prevContext, data));
  }, []);

  return (
    <DialogContext.Provider value={{
      data: context,
      setData,
      dialog
    }}>
      <Outlet />
    </DialogContext.Provider>
  )
}

export default DialogProvider;