import {DataBreaches} from "../types/dialog.ts";

export const SESSION_STORAGE_KEY = 'data';
export const FALLBACK_KANZLEI = 'Baumeister Rosing';

type Docs = "power_of_attorney" | "right_of_withdrawel" | "mandate_conditions";

const config: {
  loading: {
    delay: number,
  },
  hotline: {
    number: string,
  },
  databreach: {
    [key in DataBreaches]: {
      date: string,
      name: string,
      opponent: string,
      docs: {
        [key in Docs]: {
          insurance: string,
          no_insurance: string,
        }
      }
    }
  }
} = {
  loading: {
    delay: 750,
  },
  hotline: {
    number: '0211 33 99 66 33',
  },
  databreach: {
    facebook: {
      name: "Facebook",
      opponent: "Meta Platforms Ireland Ltd.",
      date: "Mai 2019",
      docs: {
        power_of_attorney: {
          insurance: "https://app.helpcheck.de/datenleck/vollmacht/muster/brr/facebook",
          no_insurance: "https://app.helpcheck.de/datenleck/vollmacht/muster/helpcheck/facebook",
        },
        right_of_withdrawel: {
          insurance: "https://app.helpcheck.de/datenleck/widerrufsbelehrung/brr",
          no_insurance: "https://app.helpcheck.de/datenleck/widerrufsbelehrung/helpcheck",
        },
        mandate_conditions: {
          insurance: "https://www.baumeister-rosing.de/mandatsbedingungen/",
          no_insurance: "",
        }
      },
    },
    deezer: {
      name: "Deezer",
      opponent: "Deezer S.A.",
      date: "Januar 2019",
      docs: {
        power_of_attorney: {
          insurance: "https://app.helpcheck.de/datenleck/vollmacht/muster/brr/deezer",
          no_insurance: "https://app.helpcheck.de/datenleck/vollmacht/muster/helpcheck/deezer",
        },
        right_of_withdrawel: {
          insurance: "https://app.helpcheck.de/datenleck/widerrufsbelehrung/brr",
          no_insurance: "https://app.helpcheck.de/datenleck/widerrufsbelehrung/helpcheck",
        },
        mandate_conditions: {
          insurance: "https://www.baumeister-rosing.de/mandatsbedingungen/",
          no_insurance: "",
        }
      },
    },
    twitter: {
      name: "Twitter",
      opponent: "Twitter International Unlimited Company",
      date: "März 2021",
      docs: {
        power_of_attorney: {
          insurance: "https://app.helpcheck.de/datenleck/vollmacht/muster/brr/twitter",
          no_insurance: "https://app.helpcheck.de/datenleck/vollmacht/muster/helpcheck/twitter",
        },
        right_of_withdrawel: {
          insurance: "https://app.helpcheck.de/datenleck/widerrufsbelehrung/brr",
          no_insurance: "https://app.helpcheck.de/datenleck/widerrufsbelehrung/helpcheck",
        },
        mandate_conditions: {
          insurance: "https://www.baumeister-rosing.de/mandatsbedingungen/",
          no_insurance: "",
        }
      },
    }
  }
};

export default config;