import {DialogRes} from "../../types/dialog.ts";
import apiGetInsurances from "../../api/lifeinsurance/apiGetInsurances.ts";
import {SelectFieldOption} from "../../types/elements.ts";
import {Insurance} from "../../types/lifeinsurance/response.ts";
import {Typography} from "@mui/material";
import {faEuro} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

/*
Risiko
und
Alter
 */

export enum ContractType {
  LIFEINSURANCE = 'lebensversicherung',
  PENSIONINSURANCE = 'rentenversicherung',
  EMPLOYERFUNDEDPENSION = 'betriebliche-altersvorsorge',
  PURETERMLIFEINSURANCE = 'reine-risikolebensversicherung',
  OTHER = 'other',
}

export enum ContractExists {
  YES = 'running',
  FREE = 'free',
  QUITTED = 'cancelled',
  EXPIRED = 'expired',
}

export const INSURANCE_YEAR_FROM = 1994;
export const INSURANCE_YEAR_TO = 2007;

const lifeinsurance: DialogRes = {
  steps: {
    'check': {
      fields: {
        'contracts_to_check': {
          type: 'radio',
          label: 'Wie viele Verträge möchten Sie prüfen lassen?',
          options: [
            {label: '1', value: '1'},
            {label: '2 oder mehr', value: '2+'}
          ],
          row: {
            xs: true,
          },
          required: false,
        },
        'rsv': {
          type: 'autocomplete',
          label: 'Versicherungsgesellschaft',
          placeholder: 'z.B. Advocard, ARAG, HUK',
          options: async (): Promise<SelectFieldOption[]> => {
            try {
              const res = await apiGetInsurances();
              const insurances = await res.json() as Insurance[];
              return insurances
                .sort((a, b) => a.id > b.id ? 1 : -1)
                .reduce<Insurance[]>((acc, insurance) => {
                  if (!acc.find(({fullname}) => fullname === insurance.fullname)) {
                    acc.push(insurance);
                  }
                  return acc;
                }, [])
                .map((insurance) => ({
                  label: insurance.fullname,
                  value: insurance.id
                }))
                .sort((a, b) => a.label > b.label ? 1 : -1)
            } catch {
              return []
            }
          },
          required: true
        },
        'insurance_year': {
          type: 'autocomplete',
          label: 'Vertragsbeginn',
          placeholder: '1996',
          options: (): SelectFieldOption[] => {
            const  from = INSURANCE_YEAR_FROM;
            const to = INSURANCE_YEAR_TO;
            const years: SelectFieldOption[] = [
              {
                label: `Vor ${from}`,
                value: (from - 1).toString(),
              }
            ];
            for(let i = from; i <= to; i++) {
              years.push({
                label: i.toString(),
                value: i.toString()
              });
            }
            years.push({
              label: `Nach ${to}`,
              value: (to + 1).toString(),
            });
            return years;
          },
          required: true
        },
        'insurance_year_dont_know': {
          type: 'checkbox',
          label: "Weiß ich nicht",
        },
        'contract_type': {
          type: 'autocomplete',
          label: 'Art der Versicherung',
          placeholder: 'Bitte wählen',
          info: {
            title: 'Welche Versicherung habe ich?',
            text: (
              <Typography variant="body1">
                <b>Lebensversicherung</b>
                <br/>
                Die Lebensversicherung ...
                <br/><br/>
                <b>Rentenversicherung</b>
                <br/>
                Die Rentenversicherung ...
                <br/><br/>
                <b>Betriebliche Altersvorsorge</b>
                <br/>
                Die Betriebliche Altersvorsorge ...
                <br/><br/>
                <b>Reine Risikolebensversicherung</b>
                <br/>
                Die reine Risikolebensversicherung ...
              </Typography>
            ),
            after: false,
          },
          options: [
            {label: 'Lebensversicherung', value: ContractType.LIFEINSURANCE},
            {label: 'Rentenversicherung', value: ContractType.PENSIONINSURANCE},
            {label: 'Betriebliche Altersvorsorge', value: ContractType.EMPLOYERFUNDEDPENSION},
            {label: 'Reine Risikolebensversicherung', value: ContractType.PURETERMLIFEINSURANCE},
            {label: 'Andere / Weiß ich nicht', value: ContractType.OTHER},
          ],
          required: true,
        },
        'contract_exists': {
          type: 'autocomplete',
          label: 'Besteht die Versicherung noch?',
          placeholder: 'Bitte wählen',
          options: [
            {label: 'Ja, ich zahle weiterhin ein', value: ContractExists.YES},
            {label: 'Beitragsfrei / Pausiert', value: ContractExists.FREE},
            {label: 'Gekündigt', value: ContractExists.QUITTED},
            {label: 'Ausgelaufen / Ausgezahlt', value: ContractExists.EXPIRED},
          ],
          required: true,
        },
        'insurance_last_payment': {
          type: 'number',
          placeholder: '100,00',
          props: {
            step: 0.01,
            min: 0,
          },
          adornment: {
            end: <FontAwesomeIcon icon={faEuro} />
          },
          label: (
            <>
              Zuletzt gezahlter Beitrag <Typography variant="body1" component="span">(optional)</Typography>
            </>
          ),
          required: false,
          info: {
            title: 'Titel',
            text: 'Text',
            after: false,
          },
        },
        'insurance_value': {
          type: 'number',
          placeholder: '100,00',
          props: {
            step: 0.01,
            min: 0,
          },
          adornment: {
            end: <FontAwesomeIcon icon={faEuro} />
          },
          label: (data) => {
            let text = 'Aktueller Rückkaufswert';
            if (data?.contract_exists === ContractExists.QUITTED) {
              text = 'Auszahlungsbetrag bei Kündigung';
            } else if (data?.contract_exists === ContractExists.EXPIRED) {
              text = 'Auszahlungsbetrag bei Auslauf';
            }

            return (
              <>
                {text} <Typography variant="body1" component="span">(optional)</Typography>
              </>
            );
          },
          required: false,
          info: {
            title: 'Titel',
            text: 'Text',
            after: false,
          },
        },
        'insurance_expiry_date': {
          type: 'date',
          placeholder: '2012',
          label: (data) => {
            let text = 'Datum des Vertragsauslaufs';
            if (data?.contract_exists === ContractExists.QUITTED) {
              text = 'Datum der Kündigung';
            }

            return (
              <>
                {text} <Typography variant="body1" component="span">(optional)</Typography>
              </>
            );
          },
          required: false,
          info: {
            title: 'Titel',
            text: 'Text',
            after: false,
          },
        },
        'legal_protection_insurance': {
          type: 'radio',
          label: 'Haben Sie eine Rechtsschutzversicherung?',
          options: [
            {label: 'Ja', value: 'yes'},
            {label: 'Nein', value: 'no'},
          ],
          row: {
            xs: true,
          },
          required: true,
        },
        'legal_protection_insurance_year': {
          type: 'radio',
          label: 'Wann wurde Ihre Rechtsschutzversicherung abgeschlossen?',
          options: [
            {label: 'Vor 2019', value: 'before_2019'},
            {label: 'Nach 2019', value: 'after_2019'},
          ],
          row: {
            xs: true,
          },
          required: true,
        },
        'legal_protection_insurance_year_dont_know': {
          type: 'checkbox',
          label: "Weiß ich nicht",
        },
        'phone': {
          type: 'phone',
          label: 'Telefonnummer (für Rückfragen)',
          placeholder: '1601234567',
          required: true,
        },
        'notice': {
          type: 'textarea',
          placeholder: 'Müssen wir noch etwas beachten?',
          label: 'Anmerkung (optional)',
          required: false
        },
      },
    },
    'handin': {
      fields: {
        'contract_documents_exists': {
          type: 'radio',
          label: (_, context) => `Haben Sie noch originale Vertragsunterlagen aus ${context.check?.insurance_year || context.jumpin.contract.contract_start}?`,
          options: [
            {label: 'Ja', value: 'yes'},
            {label: 'Nein', value: 'no'},
            {label: 'Teilweise', value: 'partially'},
          ],
          row: {
            xs: true,
          },
          required: true,
        },
        'contract_documents_exists_dont_know': {
          type: 'checkbox',
          label: "Weiß ich nicht",
        },
        'contract_documents_upload': {
          type: 'upload',
          label: 'Unterlagen hochladen',
          uploadHint: 'Datei auswählen oder hier ablegen',
          required: false,
          props: (_, context) => ({
            path: `/life-insurance/${context?.jumpin?.id}/documents`,
            dropzone: {
              maxSizeBytes: 200000000,
              maxFiles: 10,
              multiple: true,
              canRemove: true,
              accept: {
                'image/jpeg': ['.jpg', '.jpeg'],
                'image/png': ['.png'],
                'application/pdf': ['.pdf'],
              },
            }
          }),
        },
        'contract_documents_upload_hand_in_later': {
          type: 'checkbox',
          label: "Reiche ich nach",
          required: false,
        },
      },
    },
    'thank_you': {
      fields: {
        'password': {
          type: 'password',
          label: 'Passwort festlegen',
          placeholder: 'Sicheres Passwort festlegen',
          required: true,
        },
        'password_repeat': {
          type: 'password',
          label: 'Passwort wiederholen',
          placeholder: 'Passwort wiederholen',
          required: true,
        }
      },
    },
    'thank_you_2': {},
    'registered': {},
  }
};

export default lifeinsurance;