import {
  ActionFunctionArgs,
  createBrowserRouter,
  json,
  LoaderFunctionArgs,
  redirect,
  RouterProvider,
} from "react-router-dom";
import {DialogPage} from "../pages";
import DialogProvider from "../provider/DialogProvider.tsx";
import apiLoadData from "../api/apiLoadData.ts";
import {DialogName, StepName} from "../types/dialog.ts";
import apiLoadDialog from "../api/apiLoadDialog.ts";
import dialogs from "../config/dialogs";
import apiLoadStepData from "../api/apiLoadStepData.ts";
import apiSaveStepData from "../api/apiSaveStepData.ts";
import {FC} from "react";
import {SESSION_STORAGE_KEY} from "../config/config.ts";
import {generateUrl} from "../helper/helper.ts";
import Layout from "./Layout.tsx";

const browserRouter = createBrowserRouter([
  {
    path: "/",
    loader: async () => redirect("/databreach"),
  },
  {
    path: "jumpin/:dialog",
    loader: async ({ request, params: {dialog: dialogName}}: LoaderFunctionArgs) => {
      const url= new URL(request.url);
      const params = new URLSearchParams(url.search);
      const dialog = dialogs[dialogName as DialogName];

      const prevData = (await apiLoadStepData(dialogName as DialogName, "jumpin")) || {};
      const jumpinData = await dialog.jumpin.load.pre(params, prevData);

      sessionStorage.removeItem(SESSION_STORAGE_KEY);
      if (jumpinData) {
        await apiSaveStepData(dialogName as DialogName, 'jumpin', jumpinData);
      }

      const [target, data] = await dialog.jumpin.load.post(params, jumpinData);
      if (data) {
        await apiSaveStepData(dialogName as DialogName, target, data);
      }

      return redirect(generateUrl(target, dialogName as DialogName));
    },
    action: async ({ request, params: {dialog: dialogName}}: ActionFunctionArgs) => {
      const dialog = dialogs[dialogName as DialogName];

      if (!dialog.jumpin.action) {
        return;
      }

      const [target, data, payload] = await dialog.jumpin.action.pre(request);

      if (data) {
        await apiSaveStepData(dialogName as DialogName, target, data);
      }

      await dialog.jumpin.action.post(request, payload);

      return redirect(generateUrl(target, dialogName as DialogName));
    },
  },

  {
    path: ":dialog",
    element: <DialogProvider />,
    loader: async ({params: {dialog: dialogName}}: LoaderFunctionArgs) => ({
      data: await apiLoadData(dialogName as DialogName),
      dialog: await apiLoadDialog(dialogName as DialogName),
    }),
    children: [
      {
        element: <Layout/>,
        children: [
          {
            path: ":step?/:id?",
            element: <DialogPage />,
            loader: async ({request, params: {dialog: dialogName, step: stepName}}: LoaderFunctionArgs) => {
              const dialog = dialogs[dialogName as DialogName];
              if (!dialog) throw redirect("/");
              if (!stepName) throw redirect(generateUrl(Object.keys(dialog.steps)[0], dialog.name));

              // Quick Fix route for jumpin
              if (stepName === "jumpin") {
                const url= new URL(request.url);
                throw redirect(`${generateUrl(`/jumpin/:dialog`, dialogName as DialogName)}${url.search}`);
              }

              // Clear session storage if we are on the first step
              if (dialog.steps?.[stepName]?.clear) {
                const utm = (await apiLoadStepData(dialogName as DialogName, "jumpin"))?.utm;
                sessionStorage.removeItem(SESSION_STORAGE_KEY);
                if (utm) {
                  await apiSaveStepData(dialogName as DialogName, 'jumpin', {
                    utm,
                  });
                }
              }

              return apiLoadStepData(dialogName as DialogName, stepName as StepName);
            },
            action: async ({ request, params }: ActionFunctionArgs) => {
              const { data, next } = await request.json();

              const {dialog: dialogName, step: stepName} = params;

              // ToDo: Handle errors when api is implemented and returns errors
              try {
                await apiSaveStepData(dialogName as DialogName, stepName as StepName, data);
              } catch (e) {
                console.error(e);
              }

              if (next) {
                return redirect(generateUrl(next, dialogName as DialogName));
              }

              return json({success: true}, {status: 200});
            },
          }
        ]
      },
    ]
  },
]);

const Router: FC = () => {
  return (
    <RouterProvider router={browserRouter} />
  )
}

export default Router;
