import {Breakpoint} from "@mui/system/createTheme/createBreakpoints";
import {useMediaQuery, useTheme} from "@mui/material";
import {FC, PropsWithChildren, useCallback, useMemo} from "react";
import BreakpointContext, {BreakpointSolver} from "../context/BreakpointContext.tsx";

const BreakpointProvider: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();
  const keys: readonly Breakpoint[] = [...theme.breakpoints.keys].reverse();

  return keys.reduce((acc, cur) =>
    <SingleBreakpoint bp={cur}>{acc}</SingleBreakpoint>, children);
};

const SingleBreakpoint: FC<PropsWithChildren<{bp: Breakpoint}>> = ({ children, bp }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up(bp));

  const supKeys = useMemo(
    () => {
      const keys = [...theme.breakpoints.keys];
      return keys.slice(0, keys.findIndex((k) => k === bp) + 1).reverse();
    },
    [bp, theme.breakpoints.keys]
  );

  const solve: BreakpointSolver = useCallback((configs, fallback) => {
    if (configs === undefined) return fallback;
    const key = supKeys.find((sk) => configs[sk] !== undefined);
    return (key && configs[key] !== undefined) ? configs[key] : fallback;
  }, [supKeys]) as BreakpointSolver;

  if (!matches) {
    return children;
  }

  return (
    <BreakpointContext.Provider value={{breakpoint: bp, solve}}>
      { children }
    </BreakpointContext.Provider>
  );
};

export default BreakpointProvider;