import bcrypt from 'bcryptjs';
import {StepData} from "../../types/dialog.ts";
import {PasswordPayload} from "../../types/payloads.ts";

const apiPostPassword = async (id: string, data: StepData) => {
  const { password} = data;

  const payload: PasswordPayload = {
    customer: {
      password: await bcrypt.hashSync(password as string, 13),
    },
  };

  return await fetch(`${import.meta.env.VITE_API_URL || '/api/v1'}/life-insurance/${id}`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(payload),
  });
};

export default apiPostPassword;
