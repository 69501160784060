const apiGetInsurances = async () => {
    return fetch(`${import.meta.env.VITE_API_URL || '/api/v1'}/services/life-insurance/companies`, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
    });
}

export default apiGetInsurances;