import {FormControl, FormLabel, InputAdornment, TextField} from "@mui/material";
import useLabelRenderer from "../../hooks/useTitelRenderer.tsx";
import {FC, useState} from "react";
import {
  EmailField,
  InputField,
  PasswordField,
  PhoneField,
  ZipcodeField,
  StreetHousenumberField,
  UsernameField, CityField, TextareaField, NumberField, FieldLabel
} from "../../types/elements.ts";
import {FieldValue} from "../../types/dialog.ts";
import FormLabelWithInfo from "../FormLabelWithInfo.tsx";
import FormInfo from "../FormInfo.tsx";
import {InputBaseComponentProps} from "@mui/material/InputBase/InputBase";

const Input: FC<{
  field: |
    EmailField |
    InputField |
    TextareaField |
    PasswordField |
    UsernameField |
    StreetHousenumberField |
    PhoneField |
    CityField |
    NumberField |
    ZipcodeField,
  onChange: (value: FieldValue) => void,
  value: string,
  name: string,
  error: string | null,
  disabled: boolean,
  adornment?: {
    start?: FieldLabel,
    end?: FieldLabel,
  },
  type: 'email' | 'text' | 'password' | 'tel' | 'number',
  inputProps?: InputBaseComponentProps,
}> = (
  {
    field,
    onChange,
    value,
    name,
    error,
    disabled,
    type,
    adornment,
    inputProps,
  }
) => {
  const renderLabel = useLabelRenderer();

  const [infoOpen, setInfoOpen] = useState(false);

  const label = renderLabel(field.label);
  const inputLabel = renderLabel(field.inputLabel);
  const placeholder = renderLabel(field.placeholder);

  return (
    <FormControl fullWidth={true}>
      {
        label && (
          <>
            {
              field.info ? (
                <>
                  <FormLabelWithInfo
                    id={`${name}-label`}
                    onInfoClick={() => setInfoOpen(!infoOpen)}
                  >
                    {renderLabel(label)}
                  </FormLabelWithInfo>
                  {!field.info?.after && infoOpen && (<FormInfo info={field.info} />)}
                </>
              ): (
                <FormLabel id={`${name}-label`}>
                  {renderLabel(label)}
                </FormLabel>
              )
            }
          </>
        )
      }
      <TextField
        sx={{marginTop: label ? 2 : 0}}
        id={name}
        aria-labelledby={`${name}-label`}
        label={inputLabel}
        placeholder={placeholder}
        onChange={({target: {value}}) => onChange(value)}
        variant="outlined"
        value={value ?? ""}
        name={name}
        disabled={disabled}
        error={!!error}
        helperText={error}
        type={type}
        multiline={field.type === 'textarea'}
        minRows={field.type === 'textarea' ? 4 : undefined}
        InputLabelProps={{
          ...(inputLabel && placeholder ? {shrink: true} : {}),
        }}
        InputProps={{
          inputProps,
          ...( adornment?.start ? {startAdornment:
            typeof adornment.start === 'string' ? (
              <InputAdornment disableTypography sx={{color: '#252534'}} position="start">{adornment.start}</InputAdornment>
            ) : renderLabel(adornment.start)
          } : {}),
          ...( adornment?.end ? {endAdornment:
            typeof adornment.end === 'string' ? (
              <InputAdornment disableTypography sx={{color: '#252534'}} position="start">{adornment.end}</InputAdornment>
            ) : renderLabel(adornment.end)
          } : {}),
        }}
      />
      {field.info?.after && infoOpen && (<FormInfo info={field.info} />)}
    </FormControl>
  );
};

export default Input;
