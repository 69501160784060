/*
ToDo: If user gets a uuid then load data from server with that uuid
 */
import {DialogData} from "../types/dialog.ts";
import {SESSION_STORAGE_KEY} from "../config/config.ts";

const apiLoadData = async (dialogName: string): Promise<DialogData> => {
    return JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY) ?? '{}')?.[dialogName] ?? {};
}

export default apiLoadData;