import {Normalizer} from "../../types/dialog.ts";
import {phoneCountryCodeRegex} from "../../helper/helper.ts";
import {InputBaseComponentProps} from "@mui/material/InputBase/InputBase";

const normalizers: {
  [key: string]: (Normalizer<unknown>)[],
} = {
  zipcode: [
    (value) => (value as string).substring(0, 5),
  ],
  radio: [
    (value) => value === "true" || value === "false" ? value === "true" : value,
  ],
  number: [
    (value, props) => {
      if (!value) return value;
      const step = (props as InputBaseComponentProps)?.step || 0;
      const [, stepDec] = step.toString().split('.');
      const decPlaces = stepDec ? stepDec.length : 0;
      const [, dec] = (value as string).split('.');
      return dec?.length > 0 ? parseFloat(parseFloat(value as string).toFixed(Math.min(dec.length, decPlaces))) : parseFloat(value as string);
    },
  ],
  phone: [
    (value) => (value as string).trim(),
    (value) => (value as string).replace(new RegExp(phoneCountryCodeRegex), ''),
    (value) => (value as string).replace(/[^0-9]/g, ''),
    (value) => (value as string).replace(/^0/g, ''),
    (value) => (value as string).substring(0, 15),
    (value) => (value as string).trim(),
    (value) => value ? `+49${value}` : '',
  ],
};

export default normalizers;