import {FC, useCallback, useState, MouseEvent, useEffect} from "react";
import {Button, FormControl, FormHelperText, FormLabel, Grid, LinearProgress, Typography} from "@mui/material";
import {useDropzone, ErrorCode, DropzoneOptions} from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import useLabelRenderer from "../../hooks/useTitelRenderer.tsx";
import { UploadField } from "../../types/elements.ts";
import {FieldValue} from "../../types/dialog.ts";
import apiPostUpload from "../../api/lifeinsurance/apiPostUpload.ts";

const errorMessages = {
  [ErrorCode.FileTooLarge]: 'Die Datei ist zu groß',
  [ErrorCode.FileTooSmall]: 'Die Datei ist zu klein',
  [ErrorCode.FileInvalidType]: 'Die Datei hat einen ungültigen Dateityp',
  [ErrorCode.TooManyFiles]: 'Es können maximal 5 Dateien hochgeladen werden',
};

const Upload: FC<{
  field: UploadField,
  onChange: (value: FieldValue) => void,
  value: string,
  name: string,
  error: string | null,
  disabled: boolean,
  props: {
    path: string,
    dropzone: DropzoneOptions,
  },
}> = (
  {
    field,
    onChange,
    value,
    name,
    error,
    disabled,
    props,
  }
) => {
  const renderLabel = useLabelRenderer();

  const [files, setFiles] = useState<{
    id: string,
    size: number,
    name: string,
    progress: number,
    file?: File
  }[]>(value ? JSON.parse(value) : []);

  const label = renderLabel(field.label);
  const uploadHint = renderLabel(field.uploadHint);

  const onProgress = useCallback((id: string, loaded: number, total: number) => {
    setFiles((prev) => {
      const files = [...prev];
      const i = files.findIndex((f) => f.id === id);
      files.splice(i, 1, {
        ...files[i],
        progress: Math.ceil(loaded / total * 100),
      });
      return files;
    });
  }, []);

  const removeFile = useCallback((e: MouseEvent<HTMLButtonElement>, id: string) => {
    e.preventDefault();
    setFiles((prev) => {
      const files = [...prev];
      const i = files.findIndex((f) => f.id === id);
      files.splice(i, 1);
      return files;
    });
  }, []);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const id = uuidv4();
      setFiles((prev) => [...prev, {
        id,
        file,
        size: file.size,
        name: file.name,
        progress: 0,
      }]);
      const formData = new FormData();
      formData.append('files[]', file);
      apiPostUpload(props.path, formData, (loaded, total) => onProgress(id, loaded, total))
        .then(() => {
          setFiles((prev) => {
            const files = [...prev];
            const i = files.findIndex((f) => f.id === id);
            files.splice(i, 1, {
              ...files[i],
              progress: 100,
            });
            return files;
          });
        });
    });
  }, [onProgress, props.path]);

  useEffect(() => {
    const uploadedFiles = files
      .filter(({progress}) => progress === 100)
      .map(({id, size, name, progress}) => ({id, size, name, progress}));

    onChange(uploadedFiles.length > 0 ? JSON.stringify(uploadedFiles) : null);
  }, [files, onChange]);

  const {
    fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    disabled,
    onDrop,
    ...(props?.dropzone ?? {}),
  });

  const style = {
    flex: 1,
    padding: '20px',
    borderWidth: 2,
    borderRadius: 5,
    ...(disabled ? {} : {borderColor: '#044777'}),
    borderStyle: 'dashed',
    backgroundColor: '#ffffff',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };

  return (
    <FormControl fullWidth={true}>
      {
        label && (
          <FormLabel id={`${name}-label`}>
            {label}
          </FormLabel>
        )
      }
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <Typography variant="body1">{uploadHint}</Typography>
      </div>
      {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
      {
        fileRejections.length > 0 &&
        fileRejections.map(({ file, errors }) =>
          <FormHelperText error={true} key={file.name}>
            {file.name} - {Math.floor(file.size / 1024 / 1024 * 100) / 100} MB
            {errors.map(e => (
              <div key={e.code}>{errorMessages?.[e.code as keyof typeof errorMessages] ?? e.message}</div>
            ))}
          </FormHelperText>
        )
      }
      {
        files.length > 0 && (
          <>
            <br/>
            <Grid container direction="column" spacing={3}>
              {
                files.map(({id, size, progress, name}) => (
                  <Grid item container direction="column" key={id}>
                    <Grid item container direction="row" justifyContent="stretch">
                      <Grid item flex="1">
                        <Typography variant="body1">{name}</Typography>
                      </Grid>
                      <Grid item flex="0" sx={{textWrap: "nowrap", paddingX: 4}}>
                        <Typography variant="body1">{Math.floor(size / 1024 / 1024 * 100) / 100} MB</Typography>
                      </Grid>
                      <Grid item flex="0" flexBasis="24px" sx={{textAlign: "center"}}>
                        {
                          progress === 100
                            ? <Button
                              sx={{
                                color: '#000',
                                verticalAlign: 'middle',
                                display: 'inline-flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minWidth: 'auto',
                                padding: 0,
                              }}
                              onClick={(e) => removeFile(e, id)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                            : <>&nbsp;</>
                        }
                      </Grid>
                    </Grid>
                    <Grid item>
                      <LinearProgress color={progress === 100 ? "secondary" : "primary"} variant="determinate" value={progress} />
                    </Grid>
                  </Grid>
                ))
              }
            </Grid>
          </>
        )
      }
    </FormControl>
  );
};

export default Upload;
