import {FieldLabel, FieldPlaceholder} from "../types/elements.ts";
import {useCallback, useContext} from "react";
import DialogContext from "../context/DialogContext.tsx";
import StepContext from "../context/StepContext.tsx";
import {DialogText} from "../types/dialog.ts";
import {Typography} from "@mui/material";
import {OverridableStringUnion} from "@mui/types";
import {Variant} from "@mui/material/styles/createTypography";
import {TypographyPropsVariantOverrides} from "@mui/material/Typography/Typography";

type LabelType<T> = T extends (...args: never[]) => infer T ? T : never;

const useLabelRenderer = () => {
  const { data: context} = useContext(DialogContext);
  const { data } = useContext(StepContext);

  return useCallback(<T extends FieldLabel | FieldPlaceholder | DialogText>(label: T, variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>): LabelType<T> => {
    if (typeof label === 'function') {
      return label(data, context) as LabelType<T>;
    } else if (typeof label === 'undefined') {
      return null as LabelType<T>;
    } else if (typeof label === 'object') {
      return label as LabelType<T>;
    } else if (variant) {
      return (
        <Typography variant={variant}>
          {label}
        </Typography>
      ) as LabelType<T>;
    } else {
      return label as LabelType<T>;
    }
  }, [context, data]);
}
export default useLabelRenderer;
