import {DataBreaches, DialogExtension, FieldValue, StepData} from "../../types/dialog";
import {
  Alert,
  AlertTitle,
  Box,
  FormLabel,
  Grid, Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark, faCircleDot } from '@fortawesome/free-solid-svg-icons';
import BorderBox from "../../components/BorderBox.tsx";
import MarkedText from "../../components/MarkedText.tsx";
import apiPostRegistration from "../../api/databreach/apiPostRegistration.ts";
import apiPostHandIn from "../../api/databreach/apiPostHandIn.ts";
import config, {FALLBACK_KANZLEI} from "../config.ts";
import apiPostPassword from "../../api/databreach/apiPostPassword.ts";
import DatenleckCheck from "../../assets/datenleck_check.svg";
import apiGetClaimData from "../../api/databreach/apiGetClaimData.ts";
import {JumpinData} from "../../types/databreach/jumpin.ts";
import apiGetAffected from "../../api/databreach/apiGetAffected.ts";
import validators from "../fields/validators.ts";
import apiPostNewsletter from "../../api/databreach/apiPostNewsletter.ts";

const checkAffected = async (email?: string | null, phone?: string | null): Promise<[string, StepData?, boolean?]> => {
  if (!phone && !email) {
    return ['check'];
  }

  const res = await apiGetAffected(phone, email);
  const data = {
    email: email as string,
    phone: phone as string
  };

  if (res.status === 204) {
    if (phone && email) {
      return ['notAffectedAtAll', data, true];
    }
    return [phone ? 'notAffectedPhone' : 'notAffectedMail', data, true];
  } else if (res.status === 200) {
    const affectedData = await res.json();
    return [
      'affected',
      {
        ...data,
        databreach: Object.keys(affectedData)[0].toLowerCase(),
        data: affectedData,
      }
    ];
  }
  return ['check', data];
}

const checkValid = (type: string, name: string, value: FieldValue) => {
  const fieldValidators = validators[type] ?? [];
  // Check all validators
  let failedValidator = 0;
  for (const validator of fieldValidators ?? []) {
    failedValidator++;
    switch (validator.type) {
      case 'function':
        if (!validator.validate(name, value, {}, {})) {
          return failedValidator;
        }
        break;
      case 'regex':
        if (typeof value !== "string" || !(new RegExp(validator.regex)).test(value as string)) {
          return failedValidator;
        }
    }
  }
  return true;
}

const parseLinkParts = (link: string | null) => {
  return link?.split(';')
    ?.map(v => v.split('='))
    ?.reduce<StepData>((acc, v) => {
      if (v.length === 2) {
        acc[`handin_${v[0].trim()}`] = v[1]
          .trim()
          .replace(/^"/g, '')
          .replace(/"$/g, '');
      } else {
        const handinLink = v[0].trim()
          .replace(/^</g, '')
          .replace(/>$/g, '');
        acc['handin_link'] = handinLink;
        acc['handin_id'] = handinLink.split('/').pop() || "";
      }
      return acc;
    }, {});
}

const databreach: DialogExtension<JumpinData> = {
  name: 'databreach',
  hotline: {
    number: '0211 33 99 66 33',
  },
  jumpin: {
    load: {
      pre: async (params) => {
        const id = params.get('id');
        const insurance_number = params.get('rsv');
        const signature = params.get('sig');

        const utm_source = params.get('utm_source');
        const utm_medium = params.get('utm_medium');
        const utm_campaign = params.get('utm_campaign');
        const utm_term = params.get('utm_term');
        const utm_content = params.get('utm_content');
        const gclid = params.get('gclid');
        //const fbclid = params.get('fbclid');
        const ttclid = params.get('ttclid');
        const databreach = (params.get('datenleck') as string | null)?.toLowerCase() || null;

        const jumpinData: JumpinData = {
          id,
          insurance_number: !!(insurance_number && insurance_number === '0'),
          insurance: false,
          signature: !!(signature && signature === '0'),
          databreach,
          utm: {
            source: utm_source,
            medium: utm_medium,
            campaign: utm_campaign,
            term: utm_term,
            content: utm_content,
            gclid,
            //fbclid,
            ttclid,
          },
        };

        if (id) {
          try {
            const res = await apiGetClaimData(id);
            const prevData = await res.json();
            jumpinData.databreach = prevData.breach.toLowerCase();
            jumpinData.insurance_number = prevData.insurance_number;
            jumpinData.insurance = prevData.insurance;
          } catch {
            // Ignore
          }
        }

        return jumpinData;
      },
      post: async (params, jumpinData) => {
        if (jumpinData?.id && jumpinData.databreach) {
          const target = jumpinData.insurance ? 'insurance_power_of_attorney' : 'no_insurance_power_of_attorney';
          return [target];
        }

        let phone = params.get('phone');
        if (phone && checkValid("phone", "phone", phone) !== true) {
          phone = null;
        }

        let email = params.get('email');
        if (email && checkValid("email", "email", email) !== true) {
          email = null;
        }

        if (!phone && !email) {
          return ['check'];
        }

        return await checkAffected(email, phone);
      }
    },
    action: {
      pre: async (request) => {
        const { data: {phone, email, subscribe} } = await request.json();
        const [target, data, shouldSubscribe] = await checkAffected(email, phone);
        const willSubscribe = shouldSubscribe && subscribe && email;
        return [
          willSubscribe ? 'notAffectedSubscribed' : target,
          {
            ...data,
            subscribe
          },
          {
            email,
            subscribe: willSubscribe
          }
        ];
      },
      post: async (_, payload) => {
        if (payload.subscribe) {
          await apiPostNewsletter({email: payload.email});
        }
      },
    }
  },
  steps: {
    'check': {
      title: 'Sind Ihre persönlichen Daten betroffen?',
      clear: true,
      fields: {
        'email': {
          before: {
            'or': {
              component: (
                <Typography>
                  oder
                </Typography>
              )
            }
          },
        }
      },
      footer: [
        {
          type: 'next',
          label: 'Jetzt kostenlos prüfen',
          breakpoints: {
            xs: 12,
            md: 4,
          }
        }
      ],
      action: '/jumpin/:dialog',
    },
    'affected': {
      title:  () => (
        <Typography variant="h1">
          <MarkedText>
            Sie sind betroffen!
          </MarkedText>
          <br/>
          <Box component="span" sx={{zIndex: 3, position: 'relative'}}>Sichern Sie sich bis zu 5.000 € Schadensersatz.</Box>
        </Typography>
      ),
      text: ( data ) => {
        const databreachConfig =
          config.databreach[data.databreach as DataBreaches];
        return (
          <>
            <Typography variant="body1">
              Wir haben Ihre Daten erfolgreich überprüfen können und sind uns sicher,
              dass Sie vom {databreachConfig.name} Datenleck betroffen sind.
            </Typography>
            <Typography variant="body1">
              Mit helpcheck kommen Sie ohne Risiko zu Ihren Ansprüchen.
            </Typography>
          </>
        )
      },
      fields: {
        "hasRsv": {
          after: {
            'rsvNotice': {
              component: (data) =>
                data.hasRsv === false && (
                  <Alert severity="info">
                    <AlertTitle><b>Rechtsschutzversicherung</b></AlertTitle>
                    Mit einer Rechtsschutzversicherung kann der Prozess <b>erheblich beschleunigt</b> werden.
                    Wenn Sie eine Rechtsschutzversicherung haben, sollten Sie diese also auch nutzen - immerhin
                    sind Sie <b>genau für solche Fälle abgesichert</b>.
                  </Alert>
                )
            }
          },
        }
      },
      footer: [
        {
          type: 'next',
          label: 'Weiter',
          breakpoints: {
            xs: 12,
            md: 2,
          },
        }
      ],
      next: (data) => {
        if (data.hasRsv) {
          return ['insurance'];
        } else {
          return ['no_insurance'];
        }
      },
      prev: 'check',
      condition: async (data, context) => {
        if (context.no_insurance) {
          return 'no_insurance_power_of_attorney';
        } else if (context.insurance) {
          return 'insurance_power_of_attorney';
        } else if (!data?.data) {
          return 'check';
        }
      },
    },
    'notAffectedPhone': {
      title: () => (
        <BorderBox>
          <Typography variant="h1">
            Ihre Telefonnummer ist nicht betroffen.
          </Typography>
        </BorderBox>
      ),
      text: 'Prüfen Sie jetzt noch Ihre E-Mail-Adresse.',
      fields: {
        'subscribe': {
          breakpoints: {
            xs: 12,
            md: 9,
          },
          after: {
            'logo': {
              breakpoints: {
                xs: 12,
                md: 3
              },
              component: (
                <Grid container alignItems="flex-end" justifyContent="flex-end">
                  <Grid item xs={6} sm={4} md={12}>
                    <Box
                      component="img"
                      sx={{
                        height: 'auto',
                        width: '100%',
                        pt: {
                          md: 2,
                        },
                      }}
                      alt=""
                      src={DatenleckCheck}
                    />
                  </Grid>
                </Grid>
              )
            }
          }
        }
      },
      footer: [
        {
          type: 'prev',
          label: 'Zurück',
          breakpoints: {
            xs: 4,
            md: 2,
          },
        }, {
          type: 'next',
          label: 'Weiter',
          breakpoints: {
            xs: 8,
            md: 2,
          },
        }
      ],
      action: '/jumpin/:dialog',
      prev: 'check',
    },
    'notAffectedMail': {
      title: () => (
        <BorderBox>
          <Typography variant="h1">
            Ihre E-Mail-Adresse ist nicht betroffen.
          </Typography>
        </BorderBox>
      ),
      text: 'Prüfen Sie jetzt noch Ihre Telefonnummer.',
      fields: {
        'subscribe': {
          breakpoints: {
            xs: 12,
            md: 9,
          },
          after: {
            'logo': {
              breakpoints: {
                xs: 12,
                md: 3
              },
              component: (
                <Grid container alignItems="flex-end" justifyContent="flex-end">
                  <Grid item xs={6} sm={4} md={12}>
                    <Box
                      component="img"
                      sx={{
                        height: 'auto',
                        width: '100%',
                        pt: {
                          md: 2,
                        },
                      }}
                      alt=""
                      src={DatenleckCheck}
                    />
                  </Grid>
                </Grid>
              )
            }
          }
        }
      },
      footer: [
        {
          type: 'prev',
          label: 'Zurück',
          breakpoints: {
            xs: 4,
            md: 2,
          },
        }, {
          type: 'next',
          label: 'Weiter',
          breakpoints: {
            xs: 8,
            md: 2,
          },
        }
      ],
      action: '/jumpin/:dialog',
      prev: 'check',
    },
    'notAffectedAtAll': {
      title: (_, context) => {
        let text = 'Ihre Daten sind nicht betroffen.';
        if (context.notAffectedPhone) {
          text = 'Ihre E-Mail-Adresse ist nicht betroffen.';
        } else if (context.notAffectedMail) {
          text = 'Ihre Telefonnummer ist nicht betroffen.';
        }

        return (
          <BorderBox>
            <Typography variant="h1">
              { text }
            </Typography>
          </BorderBox>
        );
      },
      text: 'Jetzt den kostenlosen helpcheck Service aktivieren:',
      fields: {
        'subscribe': {
          breakpoints: {
            xs: 12,
            md: 9,
          },
          after: {
            'logo': {
              breakpoints: {
                xs: 12,
                md: 3
              },
              component: (
                <Grid container alignItems="flex-end" justifyContent="flex-end">
                  <Grid item xs={6} sm={4} md={12}>
                    <Box
                      component="img"
                      sx={{
                        height: 'auto',
                        width: '100%',
                        pt: {
                          md: 2,
                        },
                      }}
                      alt=""
                      src={DatenleckCheck}
                    />
                  </Grid>
                </Grid>
              )
            }
          }
        }
      },
      footer: [
        {
          type: 'prev',
          label: 'Zurück',
          breakpoints: {
            xs: 4,
            md: 2,
          },
        }, {
          type: 'next',
          label: 'Alarm aktivieren',
          breakpoints: {
            xs: 8,
            md: 4
          },
        }
      ],
      prev: (context) =>
        context.notAffectedMail ? 'notAffectedMail' :
          context.notAffectedPhone ? 'notAffectedPhone' :
            'check',
      action: '/jumpin/:dialog',
    },
    'notAffectedSubscribed': {
      title: (_, context) => {
        let text = 'Vielen Dank';
        if (context.notAffectedPhone) {
          text = 'Ihre E-Mail-Adresse ist nicht betroffen.';
        } else if (context.notAffectedMail) {
          text = 'Ihre Telefonnummer ist nicht betroffen.';
        }

        return (
          <BorderBox>
            <Typography variant="h1">
              { text }
            </Typography>
          </BorderBox>
        );
      },
      text: () => (
        <Grid container alignItems="flex-end" justifyContent="flex-end" spacing={4}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Wir werden weitere Datenlecks für Sie im Blick behalten und Sie informieren,
              falls Ihre Daten betroffen sind.
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Box
              component="img"
              sx={{
                height: 'auto',
                width: '100%',
              }}
              alt=""
              src={DatenleckCheck}
            />
          </Grid>
        </Grid>
      ),
      footer: [
        {
          type: 'next',
          label: 'Andere Adresse prüfen',
          breakpoints: {
            xs: 12,
            md: 4
          },
        }
      ],
      next: ['/'],
      prev: 'notEffectedAtAll',
    },
    'insurance': {
      title: 'Nur noch ein Schritt bis zur Entschädigung!',
      text: 'Wir arbeiten eng mit führenden Versicherungen zusammen und übernehmen kostenlos und stressfrei die gesamte Abwicklung für Sie.',
      fields: {
        'phone': {
          type: "country_code_phone"
        },
        'email': {
          before: {
            "personalData": {
              component: (
                <FormLabel>
                  Persönliche Daten
                </FormLabel>
              )
            }
          }
        },
        'street': {
          before: {
            "yourAddress": {
              component: (
                <FormLabel>
                  Ihre Adresse
                </FormLabel>
              )
            }
          }
        },
        'username': {
          before: {
            "username": {
              component: (_, context) => {
                const databreachConfig =
                  config.databreach[context.affected.databreach as DataBreaches];
                return (
                  <FormLabel>
                    Ihr {databreachConfig.name} Profilname (optional)
                  </FormLabel>
                )
              }
            }
          }
        },
        'data_privacy': {
          sx: {
            my: 4,
          }
        }
      },
      footer: [
        {
          type: 'prev',
          label: 'Zurück',
          breakpoints: {
            xs: 4,
            md: 2,
          },
        }, {
          type: 'next',
          label: 'Weiter',
          breakpoints: {
            xs: 4,
            md: 2,
          },
        }
      ],
      next: async (data, context) => {
        // ToDo: Handle concrete error codes
        try {
          const res = await apiPostRegistration(data, context);
          if (!res.ok) {
            return ['thank_you_2'];
          }

          const linkParts = parseLinkParts(res.headers.get('Link'));
          return ['insurance_power_of_attorney', linkParts];
        } catch {
          return ['thank_you_2'];
        }
      },
      prev: 'affected',
      condition: async (_, context) => {
        if (context.no_insurance) {
          return 'no_insurance_power_of_attorney';
        } else if (context.insurance) {
          return 'insurance_power_of_attorney';
        } else if (!context.affected) {
          return 'check';
        }
      },
    },
    'no_insurance': {
      title: 'Ohne Risiko zur Entschädigung.',
      text: (_, context) => {
        const databreachConfig =
          config.databreach[context.affected.databreach as DataBreaches];
        return (
          <Typography variant="body1">
            Wir gehen für Sie <b>außergerichtlich</b> gegen {databreachConfig.name} vor.
            Dafür bündeln wir viele betroffene Fälle, um eine Entschädigung von {databreachConfig.name} zu erhalten.
            <br/><br/>
            Im ersten Schritt werden wir {databreachConfig.name} dazu <b>schriftlich zur Zahlung auffordern</b>.
            Nur bei Erfolg fällt die <b>Erfolgsbeteiligung in Höhe von 25%</b> Ihrer Entschädigung an.
            <br/><br/>
            Sollte dieses außergerichtliche Vorgehen nicht zum Erfolg führen, prüfen wir weitere Möglichkeiten in
            Ihrem Fall und kommen mit einem individuellen Vorschlag auf Sie zu.
          </Typography>
        )
      },
      fields: {
        'email': {
          before: {
            "personalData": {
              component: (
                <FormLabel>
                  Persönliche Daten
                </FormLabel>
              )
            }
          }
        },
        'phone': {
          type: "country_code_phone"
        },
        'street': {
          before: {
            "yourAddress": {
              component: (
                <FormLabel>
                  Ihre Adresse
                </FormLabel>
              )
            }
          }
        },
        'username': {
          before: {
            "username": {
              component: (_, context) => {
                const databreachConfig =
                  config.databreach[context.affected.databreach as DataBreaches];
                return (
                  <FormLabel>
                    Ihr {databreachConfig.name} Profilname (optional)
                  </FormLabel>
                )
              }
            }
          }
        },
        'data_privacy': {
          sx: {
            my: 4,
          }
        },
      },
      footer: [
        {
          type: 'prev',
          label: 'Zurück',
          breakpoints: {
            xs: 4,
            md: 2,
          },
        }, {
          type: 'next',
          label: 'Weiter',
          breakpoints: {
            xs: 4,
            md: 2,
          },
        }
      ],
      next: async (data, context) => {
        // ToDo: Handle concrete error codes
        try {
          const res = await apiPostRegistration(data, context);
          if (!res.ok) {
            return ['thank_you_2'];
          }

          const linkParts = parseLinkParts(res.headers.get('Link'));
          return ['no_insurance_power_of_attorney', linkParts];
        } catch {
          return ['thank_you_2'];
        }
      },
      prev: 'affected',
      condition: async (_, context) => {
        if (context.no_insurance) {
          return 'no_insurance_power_of_attorney';
        } else if (context.insurance) {
          return 'insurance_power_of_attorney';
        } else if (!context.affected) {
          return 'check';
        }
      },
    },
    'insurance_power_of_attorney': {
      title: 'Vollmacht erteilen & Entschädigung sichern',
      text: (_, context) => {
        const databreachConfig =
          config.databreach[(context.affected?.databreach || context.jumpin?.databreach) as DataBreaches];
        return (
          <BorderBox>
            <Box>
              <Typography variant="body1">
                Für die rechtliche Durchsetzung Ihres Anspruchs
                gegen {databreachConfig.name} haben wir
                für Sie die besten Partner an unserer Seite. Unsere Experten-Kanzlei wird für Sie
                gegen {databreachConfig.opponent} vorgehen
                und einen Schadensersatz von bis zu 5.000 € erstreiten.
              </Typography>
              <Typography variant="body1">
                Das Beste für Sie: Dabei tragen Sie keine Kosten und kein Risiko, da dies Ihre Rechtsschutzversicherung
                übernimmt. Für Sie kann höchstens eine ggf. mit Ihrer Rechtsschutzversicherung vereinbarte
                Selbstbeteiligung anfallen.
              </Typography>
              <Typography variant="body1">
                Einfach Versicherungsnummer eintragen und die Partnerkanzlei beauftragen.
              </Typography>
            </Box>
          </BorderBox>
        );
      },
      fields: {
        'signature': {
          type: 'signature',
          signatureHint: (_, context) => {
            const { firstName, lastName } = context.insurance || {};
            if (firstName && lastName) {
              return <Typography variant="body1">{firstName as string} {lastName as string}</Typography>;
            }
            return "";
          },
          after: {
            'policy': {
              component: (_, context) => {
                const databreachConfig =
                  config.databreach[(context.affected?.databreach || context.jumpin?.databreach) as DataBreaches];
                return (
                  <Typography variant="body1">
                    Mit dieser Unterschrift beauftragen Sie unsere Partnerkanzlei {FALLBACK_KANZLEI} mit der rechtlichen
                    Durchsetzung Ihres Schadensersatzanspruches gegen die {databreachConfig.opponent}. Sie bestätigen
                    die <Link href={databreachConfig.docs.mandate_conditions.insurance} target="_blank">
                    Mandatsbedingungen</Link> sowie die Kenntnisnahme
                    der <Link href={databreachConfig.docs.right_of_withdrawel.insurance} target="_blank">
                    Widerrufsbelehrung</Link> und erteilen außerdem die
                    anwaltliche <Link href={databreachConfig.docs.power_of_attorney.insurance} target="_blank">
                    Vollmacht</Link>, eine Kostendeckungsanfrage bei Ihrer Rechtsschutzversicherung zu stellen sowie
                    Ihren Anspruch über alle Instanzen stellvertretend für Sie durchzusetzen.
                  </Typography>
                )
              }
            },
          },
          hidden: (_, context) => context.jumpin?.signature === true,
        },
        'insurance_policy_number': {
          required: (data) => !data?.insurance_policy_number_hand_in_later,
          disabled: (data) => !!data?.insurance_policy_number_hand_in_later,
          hidden: (_, context) => context.jumpin?.insurance_number === true,
        },
        'insurance_policy_number_hand_in_later': {
          required: (data) => !data?.insurance_policy_number,
          disabled: (data) => !!data?.insurance_policy_number,
          hidden: (_, context) => context.jumpin?.insurance_number === true,
          sx: {
            mt: {
              xs: -6
            }
          }
        },
      },
      footer: [
        {
          type: 'next',
          label: 'Jetzt beauftragen',
          breakpoints: {
            xs: 12,
            md: 5,
          },
        }
      ],
      next: async (data, context) => {
        await apiPostHandIn(context.insurance?.data?.handin_id || context.jumpin?.id, data);
        return ['thank_you'];
      },
      prev: 'insurance',
      condition: async (data, context) => {
        if (context.insurance_power_of_attorney && data.insurance_policy_number) {
          return 'thank_you';
        } else if (!context.insurance && !context?.jumpin?.id) {
          return 'check';
        }
      },
    },
    'no_insurance_power_of_attorney': {
      title: (_, context) => {
        const databreachConfig =
          config.databreach[(context.affected?.databreach || context.jumpin?.databreach) as DataBreaches];
        return (
          <Typography variant="h1">
            Vollmacht erteilen für unsere Zahlungs&shy;aufforderung
            an {databreachConfig.name}
          </Typography>
        )
      },
      text: (_, context) => {
        const databreachConfig =
          config.databreach[(context.affected?.databreach || context.jumpin?.databreach) as DataBreaches];
        return (
          <BorderBox>
            <Box>
              <Typography variant="body1">
                Im nächsten Schritt werden wir {databreachConfig.name} schriftlich zu Zahlung Ihres
                Schadensersatzanspruches auffordern. Sollte {databreachConfig.name} darauf nicht reagieren, wird es
                unsere spezialisierte Kanzlei mit einem anwaltlichen Schreiben versuchen. Sie zahlen hierfür - nur,
                wenn Sie Ihre Entschädigung wirklich erhalten - eine Beteiligung von 25 % an helpcheck.
              </Typography>
              <Typography variant="body1">
                Mit Ihrer Unterschrift können Sie helpcheck ganz einfach beauftragen.
              </Typography>
            </Box>
          </BorderBox>
        );
      },
      fields: {
        'signature': {
          type: 'signature',
          signatureHint: (_, context) => {
            const { firstName, lastName } = context.no_insurance || {};
            if (firstName && lastName) {
              return <Typography variant="body1">{firstName as string} {lastName as string}</Typography>;
            }
            return "";
          },
          after: {
            'policy': {
              component: (_, context) => {
                const databreachConfig =
                  config.databreach[(context.affected?.databreach || context.jumpin?.databreach) as DataBreaches];
                return (
                  <Typography variant="body1">
                    Mit dieser Unterschrift erteilen Sie helpcheck als Inkassodienstleister
                    die <Link href={databreachConfig.docs.power_of_attorney.no_insurance} target="_blank">
                    Vollmacht</Link>,
                    die { databreachConfig.opponent } zur Zahlung Ihres Schadensersatzanspruches aufzufordern. Sie
                    bestätigen zudem, die <Link href={databreachConfig.docs.right_of_withdrawel.no_insurance} target="_blank">
                    Widerrufsbelehrung</Link> zur Kenntnis genommen zu haben.
                  </Typography>
                )
              }
            },
          },
          hidden: (_, context) => context.jumpin?.signature === true,
        },
      },
      footer: [
        {
          type: 'next',
          label: <span>
            Jetzt beauftragen
            <br/>
            <Typography fontSize="small" width="100%">25 % Erfolgsbeteiligung</Typography>
          </span>,
          breakpoints: {
            xs: 12,
            md: 5,
          },
        }
      ],
      next: async (data, context) => {
        await apiPostHandIn(context.no_insurance?.data?.handin_id || context.jumpin?.id, data);
        return ['thank_you'];
      },
      prev: 'no_insurance',
      condition: async (_, context) => {
        if (context.no_insurance_power_of_attorney) {
          return 'thank_you';
        } else if (!context.no_insurance && !context?.jumpin?.id) {
          return 'check';
        }
      },
    },
    'thank_you': {
      title:  () => (
        <Typography variant="h1">
          <MarkedText>
            Vielen Dank
          </MarkedText> <Box component="span" sx={{zIndex: 3, position: 'relative'}}>für Ihre Beauftragung!</Box>
        </Typography>
      ),
      text: (_, context) => (
        <Typography variant="body1">
          {
            context.insurance ? (
              <>
                Wir haben Ihre Beauftragung an die Kanzlei {FALLBACK_KANZLEI} weitergeleitet.<br />
                Diese wird sich in den nächten Tagen bei Ihnen melden.
              </>
            ) : (
              <>
                Wir haben Ihre Angaben erhalten und werden Sie informieren, sobald es in Ihrem Fall etwas Neues gibt.
              </>
            )
          }
        </Typography>
      ),
      fields: {
        'password': {
          before: {
            "createAccount": {
              component: () => (
                <Grid container direction="column" spacing={4} rowSpacing={8}>
                  <Grid item>
                    <Typography variant="h1">
                      Status Ihres Falls jederzeit einsehen
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1">
                      Den aktuellen Status Ihres Falls sowie wichtige Dokumente können Sie zu jeder Zeit in Ihrem persönlichen Kundenbereich einsehen.
                    </Typography>
                    <Typography variant="body1">
                      Vergeben Sie zum Schutz Ihrer Daten jetzt noch ein Passwort.
                    </Typography>
                  </Grid>
                </Grid>
              )
            }
          },
          after: {
            "passord_requirements": {
              component: (data) => {
                const password = data?.password as string || "";
                const requirements = [
                  {
                    label: 'Mindestens 8 Zeichen',
                    valid: password?.length >= 8,
                  },
                  {
                    label: 'Mindestens 1 Großbuchstabe',
                    valid: /[A-Z]/.test(password),
                  },
                  {
                    label: 'Mindestens 1 Kleinbuchstabe',
                    valid: /[a-z]/.test(password),
                  },
                  {
                    label: 'Mindestens 1 Zahl',
                    valid: /[0-9]/.test(password),
                  },
                  {
                    label: 'Mindestens 1 Sonderzeichen',
                    valid: /[^A-Za-z0-9]/.test(password),
                  }
                ];

                const minRequirementsValid = requirements
                  .slice(1)
                  .filter(({ valid }) => valid)
                  .length >= 3
                ;

                return (
                  <List disablePadding dense>
                    {
                      requirements.map(({ label, valid }, i) => (
                        <ListItem disablePadding key={i}>
                          <ListItemIcon>
                            {
                              valid ? (
                                <FontAwesomeIcon color="green" icon={faCheck} />
                              ) : (
                                <FontAwesomeIcon
                                  color={(!minRequirementsValid || i === 0) && password?.length > 0 ? "red" : "gray"}
                                  icon={(!minRequirementsValid || i === 0) && password?.length > 0 ? faXmark : faCircleDot}
                                />
                              )
                            }
                          </ListItemIcon>
                          <ListItemText primary={label} />
                        </ListItem>
                      ))
                    }
                  </List>
                )
              }
            }
          },
          hidden: (_, context) =>
            !context?.insurance?.data?.handin_id && !context?.no_insurance?.data?.handin_id,
        },
        'password_repeat': {
          hidden: (_, context) =>
            !context?.insurance?.data?.handin_id && !context?.no_insurance?.data?.handin_id,
          validators: [
            {
              type: 'function',
              message: 'Die Passwörter stimmen nicht überein.',
              validate: (_, value, data) => data.password === value,
            }
          ],
        }
      },
      footer: [
        {
          type: 'next',
          label: 'Kundenkonto anlegen',
          breakpoints: {
            xs: 12,
            md: 4,
          },
        }
      ],
      next: async (data, context) => {
        try {
          await apiPostPassword(
            context?.insurance?.data?.handin_id || context?.no_insurance?.data?.handin_id,
            data
          );
        } catch {
          return [undefined, undefined, {
            'password': 'Das Passwort ist zu unsicher. Bitte wählen Sie ein anderes.',
          }];
        }

        return ['registered'];
      },
      condition: async (_, context) => {
        if (!context || Object.keys(context || {}).length <= 0) {
          return 'check';
        } else if (context.thank_you) {
          return 'registered';
        }
      },
    },
    'thank_you_2': {
      title:  () => (
        <Typography variant="h1">
          <MarkedText>
            Vielen Dank
          </MarkedText> <Box component="span" sx={{zIndex: 3, position: 'relative'}}>für Ihre Beauftragung!</Box>
        </Typography>
      ),
      text: () => (
        <Typography variant="body1">
          Wir haben Ihre Angaben erhalten und werden Sie informieren, sobald es in Ihrem Fall etwas Neues gibt.
        </Typography>
      ),
      condition: async (_, context) => {
        if (!context || Object.keys(context || {}).length <= 0) {
          return 'check';
        } else if (context.thank_you) {
          return 'registered';
        }
      },
    },
    'registered': {
      title: 'Ihr Kundenkonto wurde angelegt.',
      text: 'Mit Ihrer E-Mail-Adresse und dem Passwort können Sie sich jederzeit in Ihren persönlichen Kundenbereich einloggen.',
      bottom: typeof navigator?.canShare === "function" && navigator.canShare({
        title: "Helpcheck Datenleck",
        text: "Insgesamt sind über 10 Millionen Deutsche von Datenlecks betroffen - prüfe jetzt ob dir ein Schadensersatz zusteht.",
        url: `${window.location.origin}/databreach/check`
      }) ? {
        title: 'Helfen Sie Ihren Freunden.',
        text: () => (
          <Typography variant="body1">
            Insgesamt sind über 10 Millionen Deutsche von Datenlecks betroffen - vielleicht <b>auch Ihre Freunde</b>. Viele wissen nicht, dass ihnen Schadensersatz zusteht.
          </Typography>
        ),
        actions: [
          {
            type: 'button',
            label: 'Teilen',
            breakpoints: {
              xs: 12,
              md: 4,
            },
            onClick: () => {
              navigator.share({
                title: "Helpcheck Datenleck",
                text: "Insgesamt sind über 10 Millionen Deutsche von Datenlecks betroffen - prüfe jetzt ob dir ein Schadensersatz zusteht.",
                url: `${window.location.origin}/databreach/check`,
              })
            },
          }
        ]
      } : undefined,
      footer: [
        {
          type: 'button',
          label: 'Zum Kundenbereich',
          onClick: () => window.location.href = 'https://app.helpcheck.de/kundenbereich/login',
          breakpoints: {
            xs: 12,
            md: 4,
          },
        }
      ]
    },
  }
};

export default databreach;