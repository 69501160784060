const countries = [
  { name: "Afghanistan", country_code: "+93", iso_code: {short: "AF", long: "AFG"}},
  { name: "Albanien", country_code: "+355", iso_code: {short: "AL", long: "ALB"}},
  { name: "Algerien", country_code: "+213", iso_code: {short: "DZ", long: "DZA"}},
  { name: "Amerikanisch-Samoa", country_code: "+1-684", iso_code: {short: "AS", long: "ASM"}},
  { name: "Amerikanische Jungferninseln", country_code: "+1-340", iso_code: {short: "VI", long: "VIR"}},
  { name: "Andorra", country_code: "+376", iso_code: {short: "AD", long: "AND"}},
  { name: "Angola", country_code: "+244", iso_code: {short: "AO", long: "AGO"}},
  { name: "Anguilla", country_code: "+1-264", iso_code: {short: "AI", long: "AIA"}},
  { name: "Antarktis", country_code: "+672", iso_code: {short: "AQ", long: "ATA"}},
  { name: "Antigua und Barbuda", country_code: "+1-268", iso_code: {short: "AG", long: "ATG"}},
  { name: "Argentinien", country_code: "+54", iso_code: {short: "AR", long: "ARG"}},
  { name: "Armenien", country_code: "+374", iso_code: {short: "AM", long: "ARM"}},
  { name: "Aruba", country_code: "+297", iso_code: {short: "AW", long: "ABW"}},
  { name: "Aserbaidschan", country_code: "+994", iso_code: {short: "AZ", long: "AZE"}},
  { name: "Australien", country_code: "+61", iso_code: {short: "AU", long: "AUS"}},
  { name: "Bahamas", country_code: "+1-242", iso_code: {short: "BS", long: "BHS"}},
  { name: "Bahrain", country_code: "+973", iso_code: {short: "BH", long: "BHR"}},
  { name: "Bangladesch", country_code: "+880", iso_code: {short: "BD", long: "BGD"}},
  { name: "Barbados", country_code: "+1-246", iso_code: {short: "BB", long: "BRB"}},
  { name: "Belgien", country_code: "+32", iso_code: {short: "BE", long: "BEL"}},
  { name: "Belize", country_code: "+501", iso_code: {short: "BZ", long: "BLZ"}},
  { name: "Benin", country_code: "+229", iso_code: {short: "BJ", long: "BEN"}},
  { name: "Bermuda", country_code: "+1-441", iso_code: {short: "BM", long: "BMU"}},
  { name: "Bhutan", country_code: "+975", iso_code: {short: "BT", long: "BTN"}},
  { name: "Bolivien", country_code: "+591", iso_code: {short: "BO", long: "BOL"}},
  { name: "Bosnien und Herzegowina", country_code: "+387", iso_code: {short: "BA", long: "BIH"}},
  { name: "Botswana", country_code: "+267", iso_code: {short: "BW", long: "BWA"}},
  { name: "Brasilien", country_code: "+55", iso_code: {short: "BR", long: "BRA"}},
  { name: "Britische Jungferninseln", country_code: "+1-284", iso_code: {short: "VG", long: "VGB"}},
  { name: "Britisches Territorium im Indischen Ozean", country_code: "+246", iso_code: {short: "IO", long: "IOT"}},
  { name: "Brunei", country_code: "+673", iso_code: {short: "BN", long: "BRN"}},
  { name: "Bulgarien", country_code: "+359", iso_code: {short: "BG", long: "BGR"}},
  { name: "Burkina Faso", country_code: "+226", iso_code: {short: "BF", long: "BFA"}},
  { name: "Burundi", country_code: "+257", iso_code: {short: "BI", long: "BDI"}},
  { name: "Chile", country_code: "+56", iso_code: {short: "CL", long: "CHL"}},
  { name: "China", country_code: "+86", iso_code: {short: "CN", long: "CHN"}},
  { name: "Cookinseln", country_code: "+682", iso_code: {short: "CK", long: "COK"}},
  { name: "Costa Rica", country_code: "+506", iso_code: {short: "CR", long: "CRI"}},
  { name: "Curaçao", country_code: "+599", iso_code: {short: "CW", long: "CUW"}},
  { name: "Demokratische Republik Kongo", country_code: "+243", iso_code: {short: "CD", long: "COD"}},
  { name: "Deutschland", country_code: "+49", iso_code: {short: "DE", long: "DEU"}},
  { name: "Dominica", country_code: "+1-767", iso_code: {short: "DM", long: "DMA"}},
  { name: "Dominikanische Republik", country_code: "+1-809", iso_code: {short: "DO", long: "DOM"}},
  { name: "Dominikanische Republik", country_code: "+1-829", iso_code: {short: "DO", long: "DOM"}},
  { name: "Dominikanische Republik", country_code: "+1-849", iso_code: {short: "DO", long: "DOM"}},
  { name: "Dschibuti", country_code: "+253", iso_code: {short: "DJ", long: "DJI"}},
  { name: "Dänemark", country_code: "+45", iso_code: {short: "DK", long: "DNK"}},
  { name: "Ecuador", country_code: "+593", iso_code: {short: "EC", long: "ECU"}},
  { name: "El Salvador", country_code: "+503", iso_code: {short: "SV", long: "SLV"}},
  { name: "Elfenbeinküste", country_code: "+225", iso_code: {short: "CI", long: "CIV"}},
  { name: "Eritrea", country_code: "+291", iso_code: {short: "ER", long: "ERI"}},
  { name: "Estland", country_code: "+372", iso_code: {short: "EE", long: "EST"}},
  { name: "Falklandinseln", country_code: "+500", iso_code: {short: "FK", long: "FLK"}},
  { name: "Fidschi", country_code: "+679", iso_code: {short: "FJ", long: "FJI"}},
  { name: "Finnland", country_code: "+358", iso_code: {short: "FI", long: "FIN"}},
  { name: "Frankreich", country_code: "+33", iso_code: {short: "FR", long: "FRA"}},
  { name: "Französisch-Polynesien", country_code: "+689", iso_code: {short: "PF", long: "PYF"}},
  { name: "Färöer-Inseln", country_code: "+298", iso_code: {short: "FO", long: "FRO"}},
  { name: "Gabun", country_code: "+241", iso_code: {short: "GA", long: "GAB"}},
  { name: "Gambia", country_code: "+220", iso_code: {short: "GM", long: "GMB"}},
  { name: "Georgien", country_code: "+995", iso_code: {short: "GE", long: "GEO"}},
  { name: "Ghana", country_code: "+233", iso_code: {short: "GH", long: "GHA"}},
  { name: "Gibraltar", country_code: "+350", iso_code: {short: "GI", long: "GIB"}},
  { name: "Grenada", country_code: "+1-473", iso_code: {short: "GD", long: "GRD"}},
  { name: "Griechenland", country_code: "+30", iso_code: {short: "GR", long: "GRC"}},
  { name: "Grönland", country_code: "+299", iso_code: {short: "GL", long: "GRL"}},
  { name: "Guam", country_code: "+1-671", iso_code: {short: "GU", long: "GUM"}},
  { name: "Guatemala", country_code: "+502", iso_code: {short: "GT", long: "GTM"}},
  { name: "Guernsey", country_code: "+44-1481", iso_code: {short: "GG", long: "GGY"}},
  { name: "Guinea", country_code: "+224", iso_code: {short: "GN", long: "GIN"}},
  { name: "Guinea-Bissau", country_code: "+245", iso_code: {short: "GW", long: "GNB"}},
  { name: "Guyana", country_code: "+592", iso_code: {short: "GY", long: "GUY"}},
  { name: "Haiti", country_code: "+509", iso_code: {short: "HT", long: "HTI"}},
  { name: "Honduras", country_code: "+504", iso_code: {short: "HN", long: "HND"}},
  { name: "Hongkong", country_code: "+852", iso_code: {short: "HK", long: "HKG"}},
  { name: "Indien", country_code: "+91", iso_code: {short: "IN", long: "IND"}},
  { name: "Indonesien", country_code: "+62", iso_code: {short: "ID", long: "IDN"}},
  { name: "Irak", country_code: "+964", iso_code: {short: "IQ", long: "IRQ"}},
  { name: "Iran", country_code: "+98", iso_code: {short: "IR", long: "IRN"}},
  { name: "Irland", country_code: "+353", iso_code: {short: "IE", long: "IRL"}},
  { name: "Island", country_code: "+354", iso_code: {short: "IS", long: "ISL"}},
  { name: "Isle of Man", country_code: "+44-1624", iso_code: {short: "IM", long: "IMN"}},
  { name: "Israel", country_code: "+972", iso_code: {short: "IL", long: "ISR"}},
  { name: "Italien", country_code: "+39", iso_code: {short: "IT", long: "ITA"}},
  { name: "Jamaika", country_code: "+1-876", iso_code: {short: "JM", long: "JAM"}},
  { name: "Japan", country_code: "+81", iso_code: {short: "JP", long: "JPN"}},
  { name: "Jemen", country_code: "+967", iso_code: {short: "YE", long: "YEM"}},
  { name: "Jersey", country_code: "+44-1534", iso_code: {short: "JE", long: "JEY"}},
  { name: "Jordanien", country_code: "+962", iso_code: {short: "JO", long: "JOR"}},
  { name: "Kaimaninseln", country_code: "+1-345", iso_code: {short: "KY", long: "CYM"}},
  { name: "Kambodscha", country_code: "+855", iso_code: {short: "KH", long: "KHM"}},
  { name: "Kamerun", country_code: "+237", iso_code: {short: "CM", long: "CMR"}},
  { name: "Kanada", country_code: "+1", iso_code: {short: "CA", long: "CAN"}},
  { name: "Kap Verde", country_code: "+238", iso_code: {short: "CV", long: "CPV"}},
  { name: "Kasachstan", country_code: "+7", iso_code: {short: "KZ", long: "KAZ"}},
  { name: "Katar", country_code: "+974", iso_code: {short: "QA", long: "QAT"}},
  { name: "Kenia", country_code: "+254", iso_code: {short: "KE", long: "KEN"}},
  { name: "Kirgisistan", country_code: "+996", iso_code: {short: "KG", long: "KGZ"}},
  { name: "Kiribati", country_code: "+686", iso_code: {short: "KI", long: "KIR"}},
  { name: "Kokosinseln", country_code: "+61", iso_code: {short: "CC", long: "CCK"}},
  { name: "Kolumbien", country_code: "+57", iso_code: {short: "CO", long: "COL"}},
  { name: "Komoren", country_code: "+269", iso_code: {short: "KM", long: "COM"}},
  { name: "Kosovo", country_code: "+383", iso_code: {short: "XK", long: "XKX"}},
  { name: "Kroatien", country_code: "+385", iso_code: {short: "HR", long: "HRV"}},
  { name: "Kuba", country_code: "+53", iso_code: {short: "CU", long: "CUB"}},
  { name: "Kuwait", country_code: "+965", iso_code: {short: "KW", long: "KWT"}},
  { name: "Laos", country_code: "+856", iso_code: {short: "LA", long: "LAO"}},
  { name: "Lesotho", country_code: "+266", iso_code: {short: "LS", long: "LSO"}},
  { name: "Lettland", country_code: "+371", iso_code: {short: "LV", long: "LVA"}},
  { name: "Libanon", country_code: "+961", iso_code: {short: "LB", long: "LBN"}},
  { name: "Liberia", country_code: "+231", iso_code: {short: "LR", long: "LBR"}},
  { name: "Libyen", country_code: "+218", iso_code: {short: "LY", long: "LBY"}},
  { name: "Liechtenstein", country_code: "+423", iso_code: {short: "LI", long: "LIE"}},
  { name: "Litauen", country_code: "+370", iso_code: {short: "LT", long: "LTU"}},
  { name: "Luxemburg", country_code: "+352", iso_code: {short: "LU", long: "LUX"}},
  { name: "Macau", country_code: "+853", iso_code: {short: "MO", long: "MAC"}},
  { name: "Madagaskar", country_code: "+261", iso_code: {short: "MG", long: "MDG"}},
  { name: "Malawi", country_code: "+265", iso_code: {short: "MW", long: "MWI"}},
  { name: "Malaysia", country_code: "+60", iso_code: {short: "MY", long: "MYS"}},
  { name: "Malediven", country_code: "+960", iso_code: {short: "MV", long: "MDV"}},
  { name: "Mali", country_code: "+223", iso_code: {short: "ML", long: "MLI"}},
  { name: "Malta", country_code: "+356", iso_code: {short: "MT", long: "MLT"}},
  { name: "Marokko", country_code: "+212", iso_code: {short: "MA", long: "MAR"}},
  { name: "Marshallinseln", country_code: "+692", iso_code: {short: "MH", long: "MHL"}},
  { name: "Mauretanien", country_code: "+222", iso_code: {short: "MR", long: "MRT"}},
  { name: "Mauritius", country_code: "+230", iso_code: {short: "MU", long: "MUS"}},
  { name: "Mayotte", country_code: "+262", iso_code: {short: "YT", long: "MYT"}},
  { name: "Mazedonien", country_code: "+389", iso_code: {short: "MK", long: "MKD"}},
  { name: "Mexiko", country_code: "+52", iso_code: {short: "MX", long: "MEX"}},
  { name: "Mikronesien", country_code: "+691", iso_code: {short: "FM", long: "FSM"}},
  { name: "Moldawien", country_code: "+373", iso_code: {short: "MD", long: "MDA"}},
  { name: "Monaco", country_code: "+377", iso_code: {short: "MC", long: "MCO"}},
  { name: "Mongolei", country_code: "+976", iso_code: {short: "MN", long: "MNG"}},
  { name: "Montenegro", country_code: "+382", iso_code: {short: "ME", long: "MNE"}},
  { name: "Montserrat", country_code: "+1-664", iso_code: {short: "MS", long: "MSR"}},
  { name: "Mosambik", country_code: "+258", iso_code: {short: "MZ", long: "MOZ"}},
  { name: "Myanmar", country_code: "+95", iso_code: {short: "MM", long: "MMR"}},
  { name: "Namibia", country_code: "+264", iso_code: {short: "NA", long: "NAM"}},
  { name: "Nauru", country_code: "+674", iso_code: {short: "NR", long: "NRU"}},
  { name: "Nepal", country_code: "+977", iso_code: {short: "NP", long: "NPL"}},
  { name: "Neukaledonien", country_code: "+687", iso_code: {short: "NC", long: "NCL"}},
  { name: "Neuseeland", country_code: "+64", iso_code: {short: "NZ", long: "NZL"}},
  { name: "Nicaragua", country_code: "+505", iso_code: {short: "NI", long: "NIC"}},
  { name: "Niederlande", country_code: "+31", iso_code: {short: "NL", long: "NLD"}},
  { name: "Niederländische Antillen", country_code: "+599", iso_code: {short: "AN", long: "ANT"}},
  { name: "Niger", country_code: "+227", iso_code: {short: "NE", long: "NER"}},
  { name: "Nigeria", country_code: "+234", iso_code: {short: "NG", long: "NGA"}},
  { name: "Niue", country_code: "+683", iso_code: {short: "NU", long: "NIU"}},
  { name: "Nordkorea", country_code: "+850", iso_code: {short: "KP", long: "PRK"}},
  { name: "Norwegen", country_code: "+47", iso_code: {short: "NO", long: "NOR"}},
  { name: "Nördliche Marianen", country_code: "+1-670", iso_code: {short: "MP", long: "MNP"}},
  { name: "Oman", country_code: "+968", iso_code: {short: "OM", long: "OMN"}},
  { name: "Osttimor", country_code: "+670", iso_code: {short: "TL", long: "TLS"}},
  { name: "Pakistan", country_code: "+92", iso_code: {short: "PK", long: "PAK"}},
  { name: "Palau", country_code: "+680", iso_code: {short: "PW", long: "PLW"}},
  { name: "Palästina", country_code: "+970", iso_code: {short: "PS", long: "PSE"}},
  { name: "Panama", country_code: "+507", iso_code: {short: "PA", long: "PAN"}},
  { name: "Papua-Neuguinea", country_code: "+675", iso_code: {short: "PG", long: "PNG"}},
  { name: "Paraguay", country_code: "+595", iso_code: {short: "PY", long: "PRY"}},
  { name: "Peru", country_code: "+51", iso_code: {short: "PE", long: "PER"}},
  { name: "Philippinen", country_code: "+63", iso_code: {short: "PH", long: "PHL"}},
  { name: "Pitcairn", country_code: "+64", iso_code: {short: "PN", long: "PCN"}},
  { name: "Polen", country_code: "+48", iso_code: {short: "PL", long: "POL"}},
  { name: "Portugal", country_code: "+351", iso_code: {short: "PT", long: "PRT"}},
  { name: "Puerto Rico", country_code: "+1-787", iso_code: {short: "PR", long: "PRI"}},
  { name: "Puerto Rico", country_code: "+1-939", iso_code: {short: "PR", long: "PRI"}},
  { name: "Republik Kongo", country_code: "+242", iso_code: {short: "CG", long: "COG"}},
  { name: "Ruanda", country_code: "+250", iso_code: {short: "RW", long: "RWA"}},
  { name: "Rumänien", country_code: "+40", iso_code: {short: "RO", long: "ROU"}},
  { name: "Russland", country_code: "+7", iso_code: {short: "RU", long: "RUS"}},
  { name: "Réunion", country_code: "+262", iso_code: {short: "RE", long: "REU"}},
  { name: "Saint-Barthélemy", country_code: "+590", iso_code: {short: "BL", long: "BLM"}},
  { name: "Salomonen", country_code: "+677", iso_code: {short: "SB", long: "SLB"}},
  { name: "Sambia", country_code: "+260", iso_code: {short: "ZM", long: "ZMB"}},
  { name: "Samoa", country_code: "+685", iso_code: {short: "WS", long: "WSM"}},
  { name: "San Marino", country_code: "+378", iso_code: {short: "SM", long: "SMR"}},
  { name: "Saudi-Arabien", country_code: "+966", iso_code: {short: "SA", long: "SAU"}},
  { name: "Schweden", country_code: "+46", iso_code: {short: "SE", long: "SWE"}},
  { name: "Schweiz", country_code: "+41", iso_code: {short: "CH", long: "CHE"}},
  { name: "Senegal", country_code: "+221", iso_code: {short: "SN", long: "SEN"}},
  { name: "Serbien", country_code: "+381", iso_code: {short: "RS", long: "SRB"}},
  { name: "Seychellen", country_code: "+248", iso_code: {short: "SC", long: "SYC"}},
  { name: "Sierra Leone", country_code: "+232", iso_code: {short: "SL", long: "SLE"}},
  { name: "Simbabwe", country_code: "+263", iso_code: {short: "ZW", long: "ZWE"}},
  { name: "Singapur", country_code: "+65", iso_code: {short: "SG", long: "SGP"}},
  { name: "Sint Maarten", country_code: "+1-721", iso_code: {short: "SX", long: "SXM"}},
  { name: "Slowakei", country_code: "+421", iso_code: {short: "SK", long: "SVK"}},
  { name: "Slowenien", country_code: "+386", iso_code: {short: "SI", long: "SVN"}},
  { name: "Somalia", country_code: "+252", iso_code: {short: "SO", long: "SOM"}},
  { name: "Spanien", country_code: "+34", iso_code: {short: "ES", long: "ESP"}},
  { name: "Sri Lanka", country_code: "+94", iso_code: {short: "LK", long: "LKA"}},
  { name: "St. Helena", country_code: "+290", iso_code: {short: "SH", long: "SHN"}},
  { name: "St. Kitts und Nevis", country_code: "+1-869", iso_code: {short: "KN", long: "KNA"}},
  { name: "St. Lucia", country_code: "+1-758", iso_code: {short: "LC", long: "LCA"}},
  { name: "St. Martin", country_code: "+590", iso_code: {short: "MF", long: "MAF"}},
  { name: "St. Pierre und Miquelon", country_code: "+508", iso_code: {short: "PM", long: "SPM"}},
  { name: "St. Vincent und die Grenadinen", country_code: "+1-784", iso_code: {short: "VC", long: "VCT"}},
  { name: "Sudan", country_code: "+249", iso_code: {short: "SD", long: "SDN"}},
  { name: "Suriname", country_code: "+597", iso_code: {short: "SR", long: "SUR"}},
  { name: "Svalbard und Jan Mayen", country_code: "+47", iso_code: {short: "SJ", long: "SJM"}},
  { name: "Swasiland", country_code: "+268", iso_code: {short: "SZ", long: "SWZ"}},
  { name: "Syrien", country_code: "+963", iso_code: {short: "SY", long: "SYR"}},
  { name: "São Tomé und Príncipe", country_code: "+239", iso_code: {short: "ST", long: "STP"}},
  { name: "Südafrika", country_code: "+27", iso_code: {short: "ZA", long: "ZAF"}},
  { name: "Südkorea", country_code: "+82", iso_code: {short: "KR", long: "KOR"}},
  { name: "Südsudan", country_code: "+211", iso_code: {short: "SS", long: "SSD"}},
  { name: "Tadschikistan", country_code: "+992", iso_code: {short: "TJ", long: "TJK"}},
  { name: "Taiwan", country_code: "+886", iso_code: {short: "TW", long: "TWN"}},
  { name: "Tansania", country_code: "+255", iso_code: {short: "TZ", long: "TZA"}},
  { name: "Thailand", country_code: "+66", iso_code: {short: "TH", long: "THA"}},
  { name: "Togo", country_code: "+228", iso_code: {short: "TG", long: "TGO"}},
  { name: "Tokelau", country_code: "+690", iso_code: {short: "TK", long: "TKL"}},
  { name: "Tonga", country_code: "+676", iso_code: {short: "TO", long: "TON"}},
  { name: "Trinidad und Tobago", country_code: "+1-868", iso_code: {short: "TT", long: "TTO"}},
  { name: "Tschad", country_code: "+235", iso_code: {short: "TD", long: "TCD"}},
  { name: "Tschechische Republik", country_code: "+420", iso_code: {short: "CZ", long: "CZE"}},
  { name: "Tunesien", country_code: "+216", iso_code: {short: "TN", long: "TUN"}},
  { name: "Turkmenistan", country_code: "+993", iso_code: {short: "TM", long: "TKM"}},
  { name: "Turks- und Caicosinseln", country_code: "+1-649", iso_code: {short: "TC", long: "TCA"}},
  { name: "Tuvalu", country_code: "+688", iso_code: {short: "TV", long: "TUV"}},
  { name: "Türkei", country_code: "+90", iso_code: {short: "TR", long: "TUR"}},
  { name: "Uganda", country_code: "+256", iso_code: {short: "UG", long: "UGA"}},
  { name: "Ukraine", country_code: "+380", iso_code: {short: "UA", long: "UKR"}},
  { name: "Ungarn", country_code: "+36", iso_code: {short: "HU", long: "HUN"}},
  { name: "Uruguay", country_code: "+598", iso_code: {short: "UY", long: "URY"}},
  { name: "Usbekistan", country_code: "+998", iso_code: {short: "UZ", long: "UZB"}},
  { name: "Vanuatu", country_code: "+678", iso_code: {short: "VU", long: "VUT"}},
  { name: "Vatikan", country_code: "+379", iso_code: {short: "VA", long: "VAT"}},
  { name: "Venezuela", country_code: "+58", iso_code: {short: "VE", long: "VEN"}},
  { name: "Vereinigte Arabische Emirate", country_code: "+971", iso_code: {short: "AE", long: "ARE"}},
  { name: "Vereinigte Staaten", country_code: "+1", iso_code: {short: "US", long: "USA"}},
  { name: "Vereinigtes Königreich", country_code: "+44", iso_code: {short: "GB", long: "GBR"}},
  { name: "Vietnam", country_code: "+84", iso_code: {short: "VN", long: "VNM"}},
  { name: "Wallis und Futuna", country_code: "+681", iso_code: {short: "WF", long: "WLF"}},
  { name: "Weihnachtsinsel", country_code: "+61", iso_code: {short: "CX", long: "CXR"}},
  { name: "Weißrussland", country_code: "+375", iso_code: {short: "BY", long: "BLR"}},
  { name: "Westsahara", country_code: "+212", iso_code: {short: "EH", long: "ESH"}},
  { name: "Zentralafrikanische Republik", country_code: "+236", iso_code: {short: "CF", long: "CAF"}},
  { name: "Zypern", country_code: "+357", iso_code: {short: "CY", long: "CYP"}},
  { name: "Ägypten", country_code: "+20", iso_code: {short: "EG", long: "EGY"}},
  { name: "Äquatorialguinea", country_code: "+240", iso_code: {short: "GQ", long: "GNQ"}},
  { name: "Äthiopien", country_code: "+251", iso_code: {short: "ET", long: "ETH"}},
  { name: "Österreich", country_code: "+43", iso_code: {short: "AT", long: "AUT"}},
];

export default countries;
