import {Validator, ValidatorError} from "../../types/validator.ts";
import {phoneCountryCodeRegex} from "../../helper/helper.ts";

export const PhoneRegex = new RegExp(`${phoneCountryCodeRegex} ?[0-9]{1,14}$`);
export const StreetHousenumberRegex = /^([a-zA-ZäöüÄÖÜß.\- ]+)($| ([0-9]+[a-zA-ZäöüÄÖÜß0-9.\- ]*)$)/;
export const CityRegex = /[a-zA-ZäöüÄÖÜß]+/;

export const validatorErrors: {
  [key: string]: ValidatorError
} = {
  phone: {
    format: 'Bitte geben Sie eine gültige Telefonnummer ein.',
    length: 'Bitte geben Sie eine gültige Telefonnummer ein.',
  },
  zipcode: {
    length: 'Bitte geben Sie eine gültige Postleitzahl ein.',
  },
  city: {
    length: 'Bitte geben Sie eine gültige Stadt ein.',
    invalid: 'Bitte geben Sie eine gültige Stadt ein.',
  },
  country_code_phone: {
    length: 'Bitte geben Sie eine gültige Telefonnummer ein.',
    invalid: 'Bitte geben Sie eine gültige Telefonnummer ein.',
  },
  email: {
    invalid: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
  },
  password: {
    invalid: 'Ihr Passwort stimmt nicht mit den Anforderungen überein.',
  },
  street_housenumber: {
    invalid: 'Bitte geben Sie eine gültige Straße und Hausnummer ein.',
  }
}

const validators: {
  [key: string]: Validator[],
} = {
  phone: [
    {
      type: 'regex',
      regex: PhoneRegex,
      message: 'format'
    },
    {
      type: 'function',
      validate: (_, value) => (value as string).length < 17 && (value as string).length > 4,
      message: 'length'
    }
  ],
  zipcode: [
    {
      type: 'function',
      validate: (_, value) => (value as string).length === 5,
      message: 'length'
    }
  ],
  city: [
    {
      type: 'function',
      validate: (_, value) => (value as string).length > 0,
      message: 'length'
    },
    {
      type: 'regex',
      regex: CityRegex,
      message: 'invalid'
    },
  ],
  country_code_phone: [
    {
      type: 'regex',
      regex: PhoneRegex,
      message: 'invalid'
    },
    {
      type: 'function',
      validate: (_, value) => (value as string).length < 17 && (value as string).length > 4,
      message: 'length'
    }
  ],
  email: [
    {
      type: 'regex',
      regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      message: 'invalid'
    }
  ],
  password: [
    {
      type: 'function',
      validate: (_, value) => (
        !!value &&
        (value as string).length >= 8 &&
        [
          /[A-Z]/.test(value as string),
          /[a-z]/.test(value as string),
          /[0-9]/.test(value as string),
          /[^A-Za-z0-9]/.test(value as string)
        ].filter(Boolean).length >= 3
      ),
      message: 'invalid'
    }
  ],
  street_housenumber: [
    {
      type: 'regex',
      regex: StreetHousenumberRegex,
      message: 'invalid'
    }
  ]
};

export default validators;