import {Button, FormLabel, Grid} from "@mui/material";
import {ElementType} from "react";
import {FormLabelProps} from "@mui/material/FormLabel/FormLabel";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const FormLabelWithInfo = (
  {
    onInfoClick,
    children,
    ...props
  }: FormLabelProps<ElementType, {onInfoClick: () => void}>
) => {
  return (
    <Grid container justifyContent="space-between" alignItems="center" flexWrap="nowrap">
      <Grid item>
        <FormLabel {...props}>
          {children}
        </FormLabel>
      </Grid>
      <Grid item alignContent="center">
        <Button
          sx={{
            color: '#000',
            verticalAlign: 'middle',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '15px',
            width: '15px',
            minWidth: 'auto',
            padding: 2,
          }}
          onClick={onInfoClick}
        >
          <InfoOutlinedIcon />
        </Button>
      </Grid>
    </Grid>
  );
}

export default FormLabelWithInfo;