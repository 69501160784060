import {DialogName, StepData, StepName} from "../types/dialog";
import { merge } from "lodash";
import {SESSION_STORAGE_KEY} from "../config/config.ts";
const apiSaveStepData = async (dialogName: DialogName, stepName: StepName, data: StepData) => {
    const savedData = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY) ?? '{}');
    sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(merge(savedData, {
        [dialogName]: {
            [stepName]: data,
        }
    })));
    return true;
}

export default apiSaveStepData;
